.card-box-4 {
  box-shadow: 0pt 0pt 10pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
  margin: auto;
  width: 80%;
  height: auto;
  padding: 50px;
  margin-top: 1.8rem;
}
.faq-title {
  font: normal normal bold 32px/40px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 35px;
  margin-top: 40px;
  color: #ad81ea;
}
.faq-sub-title {
  font: normal normal normal 22px/34px Century Gothic;
  letter-spacing: 0px;
  margin-block: 10px;
  /* color: #FFFFFF; */
}
.faq-text {
  font: normal normal normal 16px/24px Century Gothic;
  letter-spacing: 0px;
  color: #3b3b3b;
  white-space: pre-line;
}
.faq-drop-item {
  border-inline: none;
  border-top: none;
}
