.body-inscrit {
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}
.fb {
  position: absolute;
  background-color: transparent;
  border: calc(0.06887vw + 0.67769px) solid transparent;
  padding: calc(0.34435vw + 18.38843px) calc(0.64435vw + 35.38843px);
  margin-top: -24px;
  margin-left: -60px;
  width: 311px;
}
.fb2{
  margin-right: 14px;
}
.login {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  border-color: #b8b8b8;
  padding-left: 15px;
}
.checkbox-div {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

/* Hide the browser's default checkbox */
.checkbox-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid;
}

/* On mouse-over, add a grey background color */
.checkbox-div:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-div input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.connect-btn {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #4f46a0;
  border-radius: 5px;
  background: linear-gradient(#6e65be, #372884);
  justify-content: space-evenly;
  font-family: "Century Gothic";
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.col-lg3 {
  flex: 0 0 auto;
  width: 20%;
}
@media screen and (max-width: 1050px) {
  .col-lg3 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .col-lg3 {
    flex: 0 0 auto;
    width: 100%;
  }
}
