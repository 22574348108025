@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.main {
  margin-left: 328px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  /* padding: 0px 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
