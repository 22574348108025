.enfin-garantie {
  font-size: 30px;
  font-weight: bold;
  color: white;
  font-family: "Century Gothic";
  text-align: center;
}
.sumilateur{
  margin-top: 10%;
  padding-right: 0px;
  margin-right: 0px;
}
.simulez-prix1 {
  font-family: "Century Gothic";
  font-size: 18px;
  color: white;
  text-align: center;
  margin-top: 20px;
}
.bloc-raison {
  background-color: #0c015f;
  margin: 0;
  display: flex;
  justify-content: center;
}
.title-bloc-raison {
  font-family: "Century Gothic";
  color: white;
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.paragraphe-bloc-raison {
  color: white;
  font-size: 16px;
}
.title-paragraphe-bloc-raison {
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
}
.bloc-paragraphe-bloc-raison {
  margin: 40px;
  border-bottom: solid 0.5px white;
}
.title-bloc-couvert {
  font-family: "Century Gothic";
  color: #000000;
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.title-bloc-couvert1 {
  font-family: "Century Gothic";
  color: #000000;
  margin-top: 50px;
  font-size: 27px;
  font-weight: bold;
  text-align: center;
}
.title-parahraphe-couvert {
  color: #000000;
  font-size: 22px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
}
.parahraphe-couvert {
  font-size: 15px;
  margin-bottom: 30px;
}
.parahraphe-couvert1 {
  font-size: 19px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Century Gothic";
}
.image-parahraphe-couvert {
  max-width: 60px;
  max-height: 60px;
  /* margin-bottom: 20px; */
}
.image-parahraphe-couvert1 {
  max-width: 70px;
  max-height: 63px;
  margin-bottom: 20px;
}
.bloc-paragraphe-couvert {
  padding: 0 25px;
  display: flex;
  margin-bottom: 2%;
}
.lasttitle100 {
  font-size: 22px;
  font-weight: bold;
  margin-top: -10px;
  font-family: "Century Gothic";
  color: #ffffff;
}
.firsttitle100 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Lato";
  font-family: "Century Gothic";
  color: #ffffff;
}
.paragraphe-avantage {
  font-size: 17px;
  margin-top: 20px;
  font-family: "Century Gothic";
  color: #ffffff;
}
.title-parahraphe-stepper {
  color: #e4197d;
  font-size: 18px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.bloc-sinistre {
  border-radius: 8px;
  padding: 40px 15px 15px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  width: 265px;
}
.num-stepperr {
  position: absolute;
  height: 48px;
  width: 48px;
  background-color: #9285d7;
  border-radius: 60px;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  right: 40%;
  top: -20px;
}
.bloc-sinistre-boostrap {
  padding-left: 17px;
  padding-right: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 500px) {
  .bloc-sinistre-boostrap {
    padding-right: 17px;
    align-items: center;
    flex-direction: column;
    padding: 17px 17px 17px 17px;
  }
  .sumilateur{
    margin-top: 5%;
  }
}
