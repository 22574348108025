.card-panne {
  width: 270px;
  height: 100px;
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin-inline-end: 20px;
  margin-bottom: 22px;
}

.increase {
  width:25px;
  height:25px;
  cursor: pointer;
}
.check-panne {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
}

.label-panne {
  font: normal normal normal 18px/24px Century Gothic;
}