.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
}

.nav-menu-bar {
  background-color: white;
  width: 0px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  left: -100%;
  transition: 850ms;
  z-index: 1000;
}

.nav-menu-bar.active {
  left: 0px;
  transition: 350ms;
  margin-top: 0px;
  width: 328px;
}
.nav-menu-s {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  position: absolute;
  top: -150%;
  /* left: -100%; */
  transition: 550ms;
  z-index: 1000;
}

.nav-menu-s.active {
  top: 80px;
  transition: 350ms;
  margin-top: 0px;
  height: auto;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  /* min-height: 72px; */
}

.nav-text1 {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 72px;
  margin-bottom: 95px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}
.nav-text div {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}

.nav-text1 a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}


.nav-text a:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.nav-text div:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}

.nav-text1 a:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.selected a {
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.selected .div {
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}

.nav-menu-items {
  width: 100%;
  padding-left: 5px;
}

.navbar-toggle {
  background-color: rgb(150, 13, 13);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 6px;
}
