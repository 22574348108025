* {
  margin: 0;
  padding: 0;
}

.specialiste-ass {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 60px;
  margin-bottom: 10px;
}

.spec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spec1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aventage-Garantie {
  background-color: #0c015f;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.sinistre-garanty {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.partenaires {
  background: linear-gradient(#fd8cc4, #e31279);
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.newsletter {
  background-color: #0c015f;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.HomePageFooter {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.HomePageFooter-container {
  margin-top: 4%;
  margin-bottom: 4%;
}

.container-fluide {
  display: contents;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.img-arrow {
  width: 25px;
}
.div-arrow {
  display: flex;
  padding-left: 15px;
}
.gr-container {
  margin-top: 2%;
  margin-bottom: 1%;
}
.gr-container1 {
  margin-top: 3%;
  margin-bottom: 3%;
}

.newsletter-container {
  margin-top: 3%;
  margin-bottom: 6%;
}

.text-0 {
  text-align: center;
  color: #fff;
  margin-bottom: 4%;
  font-family: "Century Gothic";
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
}

.text-1 {
  text-align: center;
  margin-bottom: 5%;
  font-family: "Century Gothic";
  font-size: 35px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 0.9;
}

.text-2 {
  text-align: center;
  color: #fff;
  margin-bottom: 3%;
  font-family: "Century Gothic";
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
}

.newletter-input {
  background: #e9e6e6;
  height: 50px;
  border: transparent;
  border-radius: 10px;
  font-family: "Century Gothic";
  width: 99%;
}

.newletter-btn {
  background: linear-gradient(rgb(253, 140, 196), rgb(227, 18, 121));
  height: 50px;
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  width: -webkit-fill-available;
  border: transparent;
  border-radius: 10px;
}
.bloc-marche {
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  border-radius: 15px;
  padding: 40px 15px 0 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fafafa;
}
.parg-marche {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Century Gothic";
}
.text-font {
  color: #fff;
  font-family: "Century Gothic";
  font-size: 50px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 0.9;
}

.text-font1 {
  font-family: "Century Gothic";
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
  margin-top: 5%;
  text-align: end;
}

.text-font3 {
  font-family: "Century Gothic";
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
  margin-top: 20%;
}

.text-font2 {
  font-family: "Century Gothic";
  font-size: 23px;
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 26.4px;
  margin-top: 3%;
  margin-bottom: 2%;
  color: #ffffff;
  white-space: nowrap;
}

.color-font-gra {
  color: #fff;
  font-family: "Century Gothic";
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}

.color-font-sini {
  font-family: "Century Gothic";
  font-size: 25px;
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 30.4px;
  color: #ffffff;
}

.color-font-sini1 {
  font-family: "Century Gothic";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  color: #023a78;
  text-align: end;
}

.color-font-Footer {
  font-family: "Century Gothic";
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

.color-font-title {
  font-family: "Century Gothic";
  color: #000;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 26.4px;
  font-size: 22px;
}

.color-font-HomePageFooter {
  font-size: 17px;
  font-family: "Century Gothic";
  line-height: 1.7;
  white-space: nowrap;
  margin-top: 9px;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.center {
  justify-content: center;
}

.img {
  margin-top: 5%;
  display: grid;
  justify-content: center;
}

.margin {
  margin-top: 2%;
}
.img-slide {
  width: 250px;
}
.prg {
  background-color: #0c015f;
  margin-top: 10%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6%;
}
.footer-carossel .carousel-indicators {
  position: absolute;
  right: 60%;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: -2rem;
  margin-left: 15%;
  list-style: none;
}
/* .carousel-indicators .active {
  opacity: 1;
} */
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #001462;
}
.footer-carossel .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 8px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
}
.text-rapide {
  color: #000000;
  font-family: "Century Gothic";
  font-size: 22px;
  font-style: normal;
  font-variant: normal;
  line-height: 1.5;
  margin-bottom: 6%;
}

.steppr-user {
  display: flex;
  justify-content: center;
}
/* ///////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .title-footer {
    font-size: 24px !important;
  }
  .text-font {
    font-size: 40px !important;
  }
  .img-avantage {
    max-width: 80% !important;
  }
  .line {
    display: none !important;
  }

  .steps {
    position: absolute;
  }
  .img-footer-garanty {
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .steps {
    position: initial;
  }
}

@media screen and (max-width: 767px) {
  .steppr-user {
    display: none;
  }
  .simulation-btn {
    padding: 0 20px;
    /* width: initial !important; */
    margin-top: 20px;
  }
  .proteger-footer {
    text-align: center;
  }
  .newletter-btn {
    width: initial !important;
    margin-top: 20px;
    float: right;
  }
  .newletter-input {
    width: 100% !important;
  }
  .title-footer1 {
    font-size: 28px !important;
  }
  /* .container-menu-footer {
    display: flex;
    flex-direction: column;
  } */
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .text-font2 {
    white-space: normal !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 575px) {
  .padding0-90 {
    padding: 0px 90px;
  }
}

@media screen and (max-width: 575px) {
  .text-font2 {
    white-space: normal !important;
  }
  .padding20-40 {
    padding: 0 !important;
  }
  .menu-footer {
    flex-direction: column;
    padding-left: 50px;
  }
}

.padding0-90 {
  padding: 0px 10px;
}

.steps {
  top: 40px;
  right: 100px;
}

.img-avantage1 {
  max-width: 400px;
}
.img-avantage {
  max-width: 300px;
}
.img-avantage2 {
  max-width: 314px;
}
.title-footer {
  font-family: Century Gothic;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  text-align: center;
  margin-bottom: 100px;
}
.title-footer1 {
  font-family: Century Gothic;
  font-weight: bold;
  font-size: 22px;
  color: #ec047e;
  margin-bottom: 10%;
}
.d-flex-j {
  display: flex;
  justify-content: center;
}
.d-flex-a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-f {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.width100 {
  width: 100%;
}
.widht50 {
  width: 50%;
}
.widht70 {
  width: 70%;
}
.width95 {
  width: 85%;
}
.max-widhth99 {
  margin-top: 50px;
  max-width: 380px;
}
.padding {
  padding: 20px 40px;
}
.padding1 {
  padding-left: 120px;
}
.margin0 {
  margin: 0;
}
.img-assurance-footer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2%;
  justify-content: center;
}
.padding20-40 {
  padding: 20px 40px;
}
.img-gras-footer {
  display: flex;
  align-items: end;
  justify-content: center;
}
.num-stepperrr {
  position: absolute;
  height: 65px;
  width: 65px;
  background-color: #dfe4f8;
  border-radius: 60px;
  color: #001462;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: -36px;
}
.form3 {
  flex: 0 0 auto;
  width: 18.66666%;
}
.form4 {
  flex: 0 0 auto;
  width: 25%;
}
.div-proteger {
  padding-left: 90px;
  padding-right: 90px;
}
.div-img-proteger {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div-button-simu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.carro-div {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.div-FAQ {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
}
.div-arrow-FAQ {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  margin-top: 8px;
  cursor: pointer;
}
.img-arrow-faq-show {
  width: 25px;
  transform: rotate(90deg);
  filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.img-arrow-faq-hide {
  width: 25px;
  transform: rotate(-90deg);
  filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.proteger-aimez {
  margin-top: 30px;
  margin-bottom: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  position: absolute;
  top: 140px;
  bottom: 150px;
  left: 50%;
  border-left: 1px dashed black;
  border-left: 3px dashed #0000001a;
  width: 1px;
}
.simulation-btn {
  background: linear-gradient(rgb(253, 140, 196), rgb(227, 18, 121));
  height: 50px;
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  width: -webkit-fill-available;
  border: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.proteger-footer {
  font-size: 20px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 3%;
  color: white;
}
::placeholder {
  padding-left: 10px;
}
.img-footer-garanty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img-foot {
  height: 54%;
}
.div-icon-social {
  display: flex;
  justify-content: flex-start;
  margin-top: 29px;
}
.icon-social {
  padding: 1px 15px 15px 1px;
  font-size: xx-large;
  color: #b5b0bd;
}
@media (min-width: 1500px) {
  .title-footer {
    font-size: 30px;
  }
  .title-footer1 {
    font-size: 35px;
  }
  .img-slide {
    width: 400px;
  }
}
@media (max-width: 1499px) and (min-width: 1100px) {
  .title-footer1 {
    font-size: 25px;
  }
  .img-slide {
    width: 300px;
  }
  .title-footer {
    font-size: 22px;
  }
}
@media (max-width: 1100px) {
  .form3 {
    flex: 0 0 auto;
    width: 50%;
  }
  .form4 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 500px) {
  .max-widhth99 {
    margin-top: 50px;
    max-width: 280px;
  }
  .text-1 {
    font-size: 23px;
  }
  .div-proteger {
    padding-left: 45px;
    padding-right: 45px;
  }
  .img-arrow {
    transform: rotate(90deg);
  }
  .div-arrow {
    padding-left: 0px;
    padding-top: 15px;
  }
  .form3 {
    flex: 0 0 auto;
    width: 100%;
  }
  .form4 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1300px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media (max-width: 1300px) {
  .color-font-title {
    font-family: "Century Gothic";
    color: #000;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 26.4px;
    font-size: 22px;
  }
  .color-font-HomePageFooter {
    font-size: 15px;
    font-family: "Century Gothic";
    line-height: 1.7;
    white-space: nowrap;
    margin-top: 9px;
  }
}
@media (max-width: 830px) {
  .container-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .img-foot {
    height: 120px;
  }
  .carro-div {
    flex: 0 0 auto;
    width: 83.33333333%;
    padding-left: 20px;
  }
  .spec1 {
    display: none;
  }
}
