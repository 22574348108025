.d-flex-a-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-j-c {
  display: flex;
  justify-content: center;
}

.d-flex-d-c-w {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.question-title {
  width: initial;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #0c015f;
}
.question-sous-title {
  width: initial;
  font-size: 20px;
  margin-top: 14px;
  font-family: -webkit-pictograph;
}
.form-souscription {
  margin: 0;
}
.margin0 {
  margin: 0;
}

.checkbox-divv {
  height: 160px;
  display: flex;
  align-items: center;
}
.label-checkboxx {
  font-size: 14px;
}
.checkboxx {
  margin-right: 10px;
}
.title-form-check {
  font-size: 14px;
  font-weight: 400;
}
.div-form-oui-non {
  border-bottom: #c7c7c7 solid 1px;
  padding: 12px 26px;
  display: flex;
  justify-content: space-between;
}
.form-check {
  padding-left: 30px;
}
.form-check-label {
  font-size: 12px;
  font-weight: bold;
}
.div-form-last {
  padding: 12px 26px;
  display: flex;
  justify-content: space-between;
}
.btn-continuer {
  float: right;
  margin-top: 50px;
  background-color: #c727cc;
  border-color: #c727cc;
  padding: 8px 50px;
  margin-left: 20px;
  margin-bottom: 50px;
}
.mon-smartphone {
  color: #0c015f;
  font-weight: bold;
  font-size: 14px;
  margin-top: 70px;
  margin-bottom: 30px;
}
.label-form {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}
.input-form {
  height: 50px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #b8b8b8;
  font-size: large;
  position: relative;
  width: 100%;
  padding-left: 10px;
}
select {
  color: #b8b8b8;
  padding-left: 20px;
}
option {
  color: black;
}
.marginTop30 {
  margin-top: 14px !important;
}
input#file-upload-button {
  display: none !important;
}
.convention-formule {
  display: flex;
  padding-top: 8px;
  justify-content: center;
  padding-left: 16px;
}

.fa-check.sous {
  background-color: #d9f9f6;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-annuler {
  float: right;
  margin-top: 50px;
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  padding: 8px 50px;
}

.div-steps-souscrption {
  width: 50px;
  height: 50px;
  background-color: #d4bff4;
  border-radius: 100px;
}

.div-steps-souscrption1 {
  width: 38px;
  height: 38px;
  background-color: #f3ebff;
  border-radius: 100px;
}

.header-souscription {
  background-color: #0c015f;
  height: 80px;
  margin: 0;
  display: flex;
}

.label-checkbox {
  font-size: 14px;
}
.checkbox {
  margin-right: 10px;
}

.card-box {
  box-shadow: 10pt 10pt 6pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
}

.div-steps-wait {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f3ebff;
  border-color: #d4bff4;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-wait-con {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}

.div-steps-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f3ebff;
  border-color: #aa74e8;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-active-con {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ea097d;
  border-color: #ea097d;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-done {
  width: 50px;
  height: 50px;
  background-color: #aa74e8;
  border-radius: 50%;
  z-index: 2;
}
.div-steps-done-con {
  width: 35px;
  height: 35px;
  background-color: #ea097d;
  border-radius: 50%;
  z-index: 2;
}
/***************************** confirmation *************************/
.dot {
  background-color: #d9f9f6;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 10px;
  margin-top: 7px;
}
.dot-label {
  font: normal normal normal 10pt/20pt Century Gothic;
  margin-bottom: 0;
}
.dot-value {
  font: normal normal bold 14pt/20pt Century Gothic;
}
.value-text {
  font: normal normal bold 12pt/24pt Century Gothic;
}
._divider {
  width: 100%;
  height: 2px;
  background-color: #0000000d;
  margin-top: 28px;
  margin-bottom: 28px;
}
.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/************************** payment **********************/
.radio-center {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.text-font5 {
  font: normal normal normal 14pt/26pt Century Gothic;
  color: black;
  letter-spacing: 0px;
}
.text-font-bold {
  font: normal normal bold 14pt/26pt Century Gothic;
  letter-spacing: 0px;
}
.hr {
  width: 100% !important;
  height: 5px;

  margin-top: 25px;
  margin-left: -3rem;
  margin-right: -2rem;
  background-color: #d4bff4;
  z-index: 0;
}
.hr-con {
  width: 100% !important;
  height: 3px;

  margin-top: -30px;
  margin-left: -2rem;
  margin-right: -1rem;
  background-color: #9e9e9e;
  z-index: 0;
}
::placeholder {
  text-align: center; 
}
.hr-active {
  width: 100% !important;
  height: 5px;
  margin-top: 25px;
  margin-left: -3rem;
  margin-right: -2rem;
  background-color: #aa74e8;
  z-index: 0;
}
.hr-active-con {
  width: 100% !important;
  height: 3px;
  margin-top: -30px;
  margin-left: -2rem;
  margin-right: -1rem;
  background-color: #ea097d;
  z-index: 0;
}

.text-step {
  text-align: center;
  font: normal normal normal 14px/14px Century Gothic;
  color: #000000;
  margin-top: 20px;
  margin-left: "-12px";
  white-space: "pre-wrap";
  margin-left: -1.2rem;
}
.text-step-active {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #000000;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.text-step-con {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #ea097d;
  margin-top: 20px;
  margin-left: "-12px";
  white-space: "pre-wrap";
  margin-left: -1.2rem;
}
.text-step-active-con {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #ea097d;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.text-step-wait-con{
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #9e9e9e;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.btn-contratct-div{
  display: flex;
  justify-content: center;
}

.oui-non-btn{
  height: 45px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*//////////////////////////////////////// */
.paragraphe-notice {
  text-align: justify;
  margin-top: 70px;
  font-size: 14px;
}
.btn-telecharger {
  margin-top: 50px;
  background-color: #c727cc;
  border-color: #c727cc;
  width: 100%;
  height: 50px;
}

/* membership */
.title-member {
  font: normal normal bold 30px/24px Century Gothic;
}
.label-member {
  font: normal normal normal 14px/18px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 2px;
}

.value-member {
  font: normal normal bold 14px/18px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 2px;
}
.row-member {
  margin-left: 15px;
}
