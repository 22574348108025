.body-sinistre {
  width: 100%;
  /* padding-left: 100px; */
  padding: 50px;
}
.rep-Ques {
  margin-top: 15px;
  font-size: 14px;
  font-family: "Century Gothic";
  font-weight: normal;
}

.space {
  margin-top: 16px;
}
