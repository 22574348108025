.textCenter {
  text-align: center;
}

.Right {
  display: flex;
  justify-content: right;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Center {
  justify-content: center;
  display: flex;
}

.margin {
  margin: 10px;
}

.row {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.absoluteCenter {
  top:10px;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.bigContainer {
  width: 1000px;
}
.smallContainer{
  width: 400px;
  height: 230px;
  border-radius: 10px ;
  box-shadow: 0px 0px 15px rgba(163, 163, 163, 0.589);
}
.logoIcon{
  width: 150px;
}
.century {
  font-family:"Century Gothic" !important ;
}
.blod {
  font-weight: bold;
}
.larger {
  font-size: 28px;
}
.greenCircle {
  background-color: green;
  border-radius: 50px;
  width: 50px;
  height: 50px;
}
.qrCode {
  height: 100vh;
}
.Margin {
  margin-top: 70px;
}

