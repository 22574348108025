.btn-wrapper {
  margin-bottom: 20px;
}
.btn-valid-w {
  background-color: #c727cc;
  color: white;
  width: 9rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  margin-bottom: 5px;
}
.btn-valid {
  background-color: #c727cc;
  color: white;
  width: 11rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  
}
.btn-back-w {
  background-color: #d4d4d4;
  color: white;
  margin-right: 10px;
  width: 9rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  margin-bottom: 5px;
}

.btn-valid-w:hover{
  background-color: white;
  color: #C727CC;
  border-color: #C727CC;
}
.btn-valid:hover{
  background-color: white;
  color: #C727CC;
  border-color: #C727CC;
}
