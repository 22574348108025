.div-icon-steps {
  width: 70px;
  height: 70px;
  background-color: #D4BFF4;
  border-radius: 100px;
}
.icon-steps {
  height: 35px;
  width: 35px;
}
.flex-d-c{
  flex-direction: column;
}
hr{
  position: absolute;
    top: 35px;
    margin: 0 50px;
    width: 72% ! important;
    z-index: -1;
    height: 5px ! important;
    color: #D4BFF4;
}
.input-radio{
  display:flex;
  margin-top: 60px;
}
.form-check-input:checked {
  background-color: #AA74E8;
  border-color: #AA74E8;
}
.form-check-label{
  text-align: center;
  font: bold 16px / 24px "Century Gothic";
  color: rgb(0, 0, 0);
}
.marginTop40{
  margin-top: 40px;
}
.marginTop20{
  margin-top: 20px;
}
.check-input{
  margin-top: 30px;
}
.code-envoie{
text-decoration: underline;
font: normal normal normal 12px/26px Century Gothic;
color: #C727CC;
margin-top:20px;
}
