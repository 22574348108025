.text-navbar {
  color: black;
  font-family: "Century Gothic";
  font-size: 16px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}

.body-souscrire {
  margin: 0;
  padding: 0;
}
.box-offer-souscrire {
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
  margin-bottom: 3%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  height: 668px;
}
.modifier {
  align-items: center;
  border: 3px solid #dd6446;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #dd6446;
  height: 45px;
  width: 20%;
  margin-left: 60px;
  margin-top: 15px;
}
.modifier:hover {
  background-color: transparent;
}
.text-mod{
  font-family: "Century Gothic";
  font-size: 17px;
  font-weight: bold;
  margin-top: 0;
  color:#FFFFFF
}
.btn-souscrire {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px solid #dd6446;
  border-radius: 50px;
  background-color: #dd6446;
  margin-bottom: 2%;
  cursor: pointer;
}
.btn-protegon {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px solid #ffff;
  border-radius: 50px;
  background-color: #ffff;
  margin-bottom: 2%;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 10%;
}

.input1 {
  /* width: 563px; */
  height: 50px;
  margin-top: 1%;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  opacity: 1;
  padding-left: 10px;
  position: relative;
}

.input2 {
  /* width: 100%; */
  height: 50px;
  margin-top: 1%;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  opacity: 1;
  padding-left: 10px;
  position: relative;
}

::placeholder {
  text-align: left;
  font: normal normal normal 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #b8b8b8;
  opacity: 1;
}

.label {
  padding-left: 5px;
  text-align: left;
  font: normal normal bold 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.img-sous {
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 15px;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 19px;
}
.txt{
  font-size: 20px;
    font-weight: bold;
    font-family: "Century Gothic";
    margin-top: 15px;
}
.txt-p{
  margin-top: 10px;
  font-family: "Century Gothic";
  font-size: 15px;
  padding-right: 36px;
}
