.nomenclature {
  width: auto;
  font-size: 12px;
  margin-left: 5px;
}
.nomenclature-container {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.boule-couleur {
  width: 12px;
  height: 12px;
  border-radius: 22px;
  margin-top: 6px;
}
.tab-container {
  background-color: #f4f2ff;
  margin-top: 50px;
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
  border-radius: 8px;
}
.title-tab {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.value-tab {
  font-size: 16px;
  text-align: center;
}
.tab-sinistres {
  min-width: 820px;
}
.doc-download {
  cursor: pointer;
}
.mp-3 {
  margin-bottom: 30px !important;
}
.row-tab {
  border-radius: 8px;
  border: #ebebeb 1px solid;
  padding: 16px 0;
  margin-top: 16px;
  margin-left: 0;
  margin-right: 0;
}
.tab-scroll {
  overflow-x: auto;
}
.btn-continuer:hover {
  background-color: white;
  color: #c727cc;
  border-color: #c727cc;
}
.succ {
  margin-top: 20%;
  text-align: center;
  font-size: larger;
  font-family: "FontAwesome";
  color: green;
}
.buttonEdit{
  background-color: #f958ff;

}