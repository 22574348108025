.card-box-dash {
  box-shadow: 0pt 8pt 20pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
  width: 280px;
  height: 180px;
  background-color: #f9f4e1;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  align-items: center;
  margin-inline-end: 20px;
}

#btn-add {
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: #c727cc;
  width: 280px;
  height: 60px;
  border-bottom-left-radius: 3pt;
  border-bottom-right-radius: 3pt;
  font: normal normal normal 16px/26px Century G;
  cursor: pointer;
}

/* #btn-add:active {
  background-color: #d57dd8;
} */

#btn-add:hover {
  background-color: #ffffff;
  color: #c727cc;
  border-style: solid;
  border-width: 1px;
}
