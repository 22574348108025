* {
  margin: 0;
  padding: 0;
}
.row {
  margin: 0;
  padding: 0;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  justify-content: space-between;
}
.text-popup {
  font-size: 15px !important;
  padding-left: 5px;
  font-family: "Century Gothic" !important;
  font-weight: bold;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.text-center {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sim-text-div-right {
  background-color: #393c8f;
  padding-left: 10%;
  padding-right: 2%;
}
.sim-text-div-left {
  background-color: rgb(65 68 157);
  padding-right: 10%;
  padding-left: 2%;
}
.text-sous-sim {
  font-family: "Century Gothic";
  font-size: 20px;
  color: white;
  margin-top: 2%;
  margin-bottom: 6%;
}
.dev-par-card1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ed2d87;
  padding: 28px;
  border-radius: 10px;
}
.dev-par-card3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  padding: 28px;
  border-radius: 10px;
  box-shadow: #dee2e6 0pt 0pt 5pt 0px;
  height: 100%;
}
.pp {
  color: #ffffff;
  font-family: "Century Gothic";
  font-size: 25px;
  font-weight: bold;
}
.sous-status {
  flex: 0 0 auto;
  width: 25%;
  padding: 20px;
}
.status {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
  flex: 0 0 auto;
  width: 66%;
}
.garantie-contre {
  display: flex;
  margin-bottom: 2%;
  justify-content: center;
  flex: 0 0 auto;
  width: 60%;
}
.sous-garantie-contre {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
  width: 25%;
}
.simula-div{
  background-color: #e31279;
  border-radius: 21px;
  border: 1px solid #fff;
  margin: 16px;
}
.part-switch-prod-div {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  justify-content: center;
}
.part-switch-prod {
  flex: 0 0 auto;
  width: 25%;
  padding: 5px;
}
.dev-par-card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #243987;
  padding: 28px;
  border-radius: 10px;
}
.text-dev-card-arabe {
  color: white;
  font-size: 28px;
}
.title-sim-text {
  font-family: "Century Gothic";
  font-weight: bold;
  font-size: 23px;
  margin-top: 15px;
}
.header-container-slidePartenaire {
  width: 100%;
  height: 70vh;
  background-image: url("./BannerFullSize/slider-devenir-partenaire.jpg");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slideContact {
  width: 100%;
  height: 60vh;
  background-image: url("./BannerFullSize/sliderContact.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.header-container-slide1 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web2.png"); */
  background-image: url("./slide\ web\ 2025/mobile.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.header-container-slidee00 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BannerMeuble.png");
   */
   background-image: url("./slide\ web\ 2025/meubles.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee0 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie.png"); */
  background-image: url("./slide\ web\ 2025/carte-x-garanty.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee1 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web2.png"); */
  background-image: url("./slide\ web\ 2025/mobile.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide2 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 5.png"); */
  background-image: url("./slide\ web\ 2025/electro.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee2 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 5.png"); */
  background-image: url("./slide\ web\ 2025/electro.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide3 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 8.png"); */
  background-image: url("./slide\ web\ 2025/pc.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee3 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 8.png"); */
  background-image: url("./slide\ web\ 2025/pc.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide4 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 10.png"); */
  background-image: url("./slide\ web\ 2025/pc.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee4 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 10.png"); */
  background-image: url("./slide\ web\ 2025/optique.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee5 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web.png"); */
  background-image: url("./slide\ web\ 2025/smartwatch.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee6 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 15.png"); */
  background-image: url("./slide\ web\ 2025/télévision.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee7 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 3.png"); */
  background-image: url("./Banner\ summer\ site/summer-9.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee8 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 16.png"); */
  background-image: url("./Banner\ summer\ site/summer-1.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee9 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 17.png"); */
  background-image: url("./Banner\ summer\ site/summer-2.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee10 {
  width: 100%;
  height: 80vh;
  background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 18.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee23{
  width: 100%;
  height: 81vh;
  background-image: url("./BannerFullSize/AssuranceOptiqueMaladie.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee11 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 12.png"); */
  background-image: url("./Banner\ summer\ site/summer-4.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.garanty-logo {
  display: grid;
  justify-content: end;
  align-content: center;
  padding-right: calc(var(--bs-gutter-x) * 1.5);
}
.assurance-logo {
  display: grid;
  justify-content: start;
  align-content: center;
}
.logo-bh{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  margin-left: 60px;
}
.logo-garanty{
  display: flex;
  justify-content: center;
  border-left: 2px solid #aeaeae;
  align-items: center;
  margin-left: 6px;
}

.borde1 {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 80px;
  width: 300px;
  border-right: 1px solid #f9fafb;
  margin-left: 20px;
  margin-bottom: 20px;
}
.carousel-control-next, .carousel-control-prev {
  width: 7% !important;
}
.borde2 {
  height: auto;
  width: 300px;
  /* margin-left: 20px; */
  margin-bottom: 20px;
  display: grid;
  justify-content: start;
  align-items: center;
  justify-items: start;
}
.text-menu {
  color: #fff;
  font-family: "Century Gothic";
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  margin-left: 0;
  font-weight: bold;
}
.menu-text-dropdown {
  color: #000000;
  font-family: "Century Gothic";
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}
.div-text-slide {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  flex: 0 0 auto;
  width: 50%;
}

.connect-btn1 {
  width: 20%;
  border-radius: 10px;
  background-color: #c827cb;
  font-family: "Century Gothic";
  color: #fff;
}
.produit {
  display: flex;
  justify-content: flex-end;
}

.apropos {
  display: flex;
  justify-content: center;
}

.aide {
  display: flex;
  justify-content: start;
}

.fa-check {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-times {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-align-P-dev1 {
  text-align: center;
  color: #192d9c;
  font-family: "Century Gothic";
  font-size: 20px;
}
.form-div {
  width: 20%;
  padding-left: calc(var(--bs-gutter-x) * 0.3);
  padding-right: calc(var(--bs-gutter-x) * 0.3);
  padding-bottom: calc(var(--bs-gutter-x) * 0.3);
}
.form-div-S {
  width: 33%;
  padding-left: calc(var(--bs-gutter-x) * 3);
  padding-right: calc(var(--bs-gutter-x) * 3);
  padding-bottom: calc(var(--bs-gutter-x) * 3);
}
.oui-btn-div {
  padding-right: 5px;
}
.non-btn-div {
  padding-left: 5px;
}
.bar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  font-family: "Century Gothic";
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}
.contain {
  display: grid;
  align-content: center;
}
.garanty-switch2 {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 90px;
  margin-bottom: 60px;
}
.garanty-switch1 {
  display: flex;
  justify-content: center;
  align-content: center;
}
.tabset-icon {
  margin-top: -60px;
}
.dispnone {
  display: flex;
}
.garanty-switch {
  display: flex;
  align-content: center;
  border: 1px solid #fafafa;
  cursor: pointer;
  background-color: #fafafa;
  box-shadow: rgb(0 0 0 / 5%) 4pt 4pt 4pt;
  border-radius: 12px;
  height: 160px;
  align-items: center;
  margin-top: -28px;
  flex-direction: column;
  padding-bottom: 6%;
  padding-top: 6%;
  justify-content: space-between;
}

.smartphone-body {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  margin-bottom: 10%;
}

.garantie-Smart {
  margin-bottom: 5%;
}
.garantie-Smart1 {
  margin-bottom: 3%;
}
.simulation-prix {
  margin-bottom: 4%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.btn-hold {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.btn-decouvrezGarantie {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #4f46a0;
  border-radius: 11px;
  background: linear-gradient(#fd8cc4, #e31279);
  color: #fff;
  cursor: pointer;
}
.simulation-input {
  display: flex;
  justify-content: center;
}

.input-prix {
  height: 50px;
  border-radius: 7px;
  width: 100%;
  border: 1px solid #fafafa;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  padding-left: 15px;
  position: relative;
  background-color: #fafafa;
}

.calcule-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #4f46a0;
  border-radius: 7px;
  background: linear-gradient(#6e65be, #372884);
  justify-content: center;
  font-family: "Century Gothic";
  font-size: "19px";
  font-weight: "bold";
  color: #fff;
}
.elec-fam {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.container-footer {
  display: flex;
  justify-content: space-evenly;
}
.div-foot {
  padding: 10px;
  margin-top: 30px;
}
.img-formula {
  width: 62%;
  filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.img-formula-select {
  width: 62%;
  filter: brightness(0) invert(1);
}
.img-fam-mult-cla {
  width: 115px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
}
.img-fam-mult {
  width: 115px;
  filter: brightness(0) invert(1);
}
.img-fam-mult-cla-tab {
  width: 75px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
}
.img-fam-mult-tab {
  width: 75px;
  filter: brightness(0) invert(1);
}

.txt-img-mult-fam {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #242e87;
}
.txt-img-mult-fam-hover {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.txt-img-elec-fam {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #e60074;
  text-decoration: underline;
}
.txt-img-elec-fam-hover {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #242e87;
  text-decoration: underline;
}

.text-couvert-mut-div {
  font-family: "Century Gothic";
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 35px;
}

.img-mult-covert {
  flex-direction: column;
  padding: 15px;
  position: relative;
  flex: 0 0 auto;
  width: 20%;
}
.div-mult-fam-btn {
  flex: 0 0 auto;
  width: 50%;
}
.div-img-mult-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(36, 46, 135);
  padding-bottom: 4%;
  padding-top: 5%;
  border-radius: 20px;
  cursor: pointer;
}

.formule-offer {
  display: flex;
  justify-content: center;
}

.img-couvert {
  width: 235px;
}
.img-couvert-eclectro {
  width: 80px;
}

.text-img-couvert {
  font-family: "Century Gothic";
  font-size: 20px;
  font-weight: bold;
  margin-top: 4%;
  white-space: nowrap;
  text-align: center;
  color: #393c8f;
}

.box-offer {
  border: 3px solid;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 15%;
  padding-bottom: 15%;
  margin-bottom: 20%;
}
.marque-pop {
  display: flex;
  justify-content: center;
  background-color: #9285d7;
  height: 190px;
}
.img-formule {
  min-width: 173px;
}
.img-formule-extend {
  max-width: 255px;
}
h6 {
  margin-bottom: 0rem;
  margin-top: 0.3rem;
}
h5 {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.div-family-select-click {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-family-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* //////////////////////////////////////// */

.triplebar {
  display: inline-block;
  cursor: pointer;
}
.garantie {
  width: 85%;
}
.garantie1 {
  width: 75%;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 4px;
  background-color: #000000;
  margin: 6px 0;
  transition: 0.4s;
}
.textSize-slide-header {
  font-family: "Century Gothic";
  font-size: 70px;
  font-weight: bold;
}
.textSize-slide-header1 {
  font-size: 50px;
  font-family: "Century Gothic";
  font-weight: bold;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.btn-3 {
  font-family: "Century Gothic";
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 0px 18px;
  text-align: center;
}
.nav-menu {
  position: relative;
  z-index: 1000;
}
.text-granty-type {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.color1 {
  color: #ffffff;
}
.color2 {
  color: #e60074;
}
.color3 {
  color: rgb(35 46 133);
}
.color4 {
  color: #ffffff;
}
.d-sous-a-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formula {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #000000;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  height: 86px;
  margin-top: 17px;
  background-color: #fafafa;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}
.formula-select {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #000000;
  height: 86px;
  margin-top: 17px;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(#fd8cc4, #e31279);
  cursor: pointer;
}
.text-formula {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.formula-bloc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: 0 0 auto;
  width: 100%;
}
.formule-bloc {
  flex: 0 0 auto;
  width: 33.33333333%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 6%;
}
.form {
  flex: 0 0 auto;
  width: 50%;
}
.none {
  display: flex;
}
.headweb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  width: 33.333333%;
}
.CNDA {
  padding-right: 100px;
}
.question-souscription {
  background-color: #f3ebff;
  height: 160px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.barvol {
  top: 0;
  left: 0;
  padding: 10px 0;
  background: #d9002b;
  color: white;
  text-align: center;
}
.volgras {
  color: white;
  font-size: 25px;
  font-weight: bold;
  font-family: "system-ui";
}

.bar_content {
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: bold;
  /* transform: translateX(100%);
  animation: move 15s linear infinite; */
}

/* @keyframes move {
  to {
    transform: translateX(-80%);
  }
} */
@media screen and (max-width: 760px) {
  .elec-fam {
    flex-direction: column;
  }
}
@media screen and (max-width: 1500px) {
  .div-mult-fam-btn {
    width: 65%;
  }
  .none {
    display: none;
  }
  .status {
    width: 75%;
  }
  .garantie-contre {
    width: 68%;
  }
}
@media screen and (max-width: 1220px) {
  .volgras {
    font-size: 20px;
  }

  .bar_content {
    font-size: 18px;
    /* transform: translateX(100%);
    animation: move 15s linear infinite; */
  }
  .div-mult-fam-btn {
    width: 73%;
  }
  .garantie-contre {
    width: 73%;
  }
  .part-switch-prod-div {
    width: 75%;
  }
  .mobile-menu-toggler {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .contain {
    display: none;
  }
  .triplebar {
    display: block;
  }
  .garanty-switch {
    margin-top: -14px;
  }
  .garanty-switch2 {
    margin-top: 90px;
    margin-bottom: 60px;
  }
  .garantie {
    width: 100%;
    margin-left: 12px;
  }
  .garantie1 {
    width: 100%;
    margin-right: 10px;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    height: 95px;
  }

  .menu-mobile {
    display: flex !important;
    justify-content: center;
    margin-left: -29px;
  }
  .contain2 {
    display: flex;
    flex: 0 0 auto;
    width: 23.333333%;
  }
  .headweb {
    width: 65.333333%;
    margin-left: 2%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
.div-btn-calculate {
  justify-content: flex-start;
}
@media screen and (max-width: 1880px) and (min-width: 991px) {
  .form-div-S {
    width: 30%;
    padding-left: calc(var(--bs-gutter-x) * 2);
    padding-right: calc(var(--bs-gutter-x) * 2);
    padding-bottom: calc(var(--bs-gutter-x) * 2);
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media screen and (max-width: 1250px) {
  .garanty-switch {
    justify-content: center;
    height: 90px;
  }
  .text-granty-type {
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .text-couvert-mut-div {
    font-size: 24px;
  }
  .formule-bloc {
    width: 50%;
  }
  .formula-bloc {
    flex-direction: column;
  }
  .socialmedia-div {
    flex-direction: column;
    align-items: center;
  }
  .col-lg3 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .div-mult-fam-btn {
    width: 87%;
  }
  .img-mult-covert {
    width: 25%;
  }
  .garantie-contre {
    width: 68%;
    flex-direction: column;
    align-items: center;
  }
  .sous-garantie-contre {
    width: 50%;
    margin-top: 3%;
  }
  .sous-status {
    width: 70%;
  }
  .status {
    flex-direction: column;
  }
  .form-div {
    width: 33%;
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    padding-right: calc(var(--bs-gutter-x) * 0.3);
  }
  .form-div-S {
    width: 30%;
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .contain2 {
    display: block;
  }
  .nav-menu {
    width: 100%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
  .div-mult-fam-btn {
    width: 80%;
  }
  .img-mult-covert {
    width: 45%;
  }
  .sous-status {
    width: 70%;
  }
  .status {
    flex-direction: column;
  }
  .form-div {
    width: 50%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .form-div-S {
    width: 50%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .textSize-slide-header {
    font-size: 50px;
  }
  .textSize-slide-header1 {
    font-size: 35px;
  }
}
@media screen and (min-width: 768px) {
  .simulation-input {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 640px) {
  .txt-img-mult-fam {
    font-size: 15px;
  }
  .txt-img-mult-fam-hover {
    font-size: 15px;
  }
  .img-fam-mult-cla-tab {
    width: 50px;
  }
  .img-fam-mult-tab {
    width: 50px;
  }
  .img-fam-mult-cla {
    width: 90px;
  }
  .img-fam-mult {
    width: 90px;
  }
}

@media screen and (max-width: 767px) {
  .part-switch-prod-div {
    flex-direction: column;
    align-items: center;
  }
  .part-switch-prod {
    width: 80%;
  }
  .question-souscription {
    display: none;
  }
  .garantie-contre {
    width: 68%;
    flex-direction: column;
    align-items: center;
  }
  .sous-garantie-contre {
    width: 50%;
    margin-top: 3%;
  }
  .d-sous-a-c {
    display: none;
    justify-content: center;
    align-items: center;
  }
  .simulation-input {
    height: 235px;
  }
  .sim-text-div-left {
    padding-left: 10%;
  }
  .div-btn-calculate {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 1220px) {
  .triplebar {
    display: none;
  }
  .contain2 {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .txt-img-mult-fam {
    font-size: 12px;
    white-space: nowrap;
  }
  .txt-img-mult-fam-hover {
    font-size: 12px;
    white-space: nowrap;
  }
  .img-fam-mult-cla-tab {
    width: 30px;
  }
  .img-fam-mult-tab {
    width: 30px;
  }
  .img-fam-mult-cla {
    width: 50px;
  }
  .img-fam-mult {
    width: 50px;
  }
  .img-mult-covert {
    width: 51%;
  }
  .sous-status {
    width: 100%;
  }
  .status {
    flex-direction: column;
  }
  .header-container {
    height: 500px;
  }
  .smartphone-garanty {
    font-size: 23px !important;
  }
  .btn-garanty-smartphone {
    font-size: 16px !important;
  }
  .simulez-prix {
    font-size: 24px !important;
  }
  .simulezz-prix {
    font-size: 24px !important;
  }
  .form-div {
    width: 100%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .form-div-S {
    width: 100%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .formule-bloc {
    width: 70%;
  }
  .textSize-slide-header {
    margin-top: 50%;
    font-size: 50px;
  }
  .textSize-slide-header1 {
    font-size: 35px;
  }
  .barvol {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    /* display: none; */
  }

  .volgras {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  /* @keyframes move {
    to {
      transform: translateX(-200%);
    }
  } */
  .bar_content {
    white-space: normal;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
  }
  .header-container-slidee0 {
    background-image: url("./slide\ mobile\ 2025/garanty-carte.png");
    height: 28vh;
  }
  .header-container-slidee1 {
    background-image: url("./slide\ mobile\ 2025/meuble.png");
    height: 28vh;
  }
  .CNDA {
    padding-right: 0;
  }
  /* .header-container-slide2 {
    background-image: url("./slide2mobile.jpg");
    height: 60vh;
  } */
  .header-container-slidee2 {
    background-image: url("./slide\ mobile\ 2025/optique.png");
    height: 28vh;
  }
  .btn-wrapper {
    display: inline-grid;
    margin-bottom: 20px;
  }
  .header-container-slidee00{
    background-image: url("./slide\ mobile\ 2025/mobile.png");
    height: 28vh;
  }
  .header-container-slidee3 {
    background-image: url("./slide\ mobile\ 2025/pc.png");
    height: 28vh;
  }
  .header-container-slidee11{
    background-image: url("./slide\ mobile\ 2025/smart.png");
    height: 28vh;
  }
  .header-container-slidee4 {
    background-image: url("./slide\ mobile\ 2025/electro.png");
    height: 28vh;
  }
  .header-container-slidee5 {
    background-image: url("./BannerMobile/summer-banner-mobile10.png");
    height: 28vh;
  }
  .header-container-slidee6 {
    background-image: url("./slide\ mobile\ 2025/Télévision.png");
    height: 28vh;
  }
  .header-container-slidee7 {
    background-image: url("./BannerMobile/summer-banner-mobile9.png");
    height: 28vh;
  }
  .header-container-slidee8 {
    background-image: url("./BannerMobile/summer-banner-mobile1.png");
    height: 28vh;
  }
  .header-container-slidee9 {
    background-image: url("./BannerMobile/summer-banner-mobile2.png");
    height: 28vh;
  }
  .header-container-slidee10 {
    background-image: url("./Banner660X330/BanniereSitewebAffichage-32.png");
    height: 28vh;
  }

  .text-align-P-dev1 {
    font-size: 16px;
  }

  .div-text-slide {
    flex: 0 0 auto;
    width: 100%;
  }
  .img-garanty-type {
    margin-bottom: 3px;
  }
  .div-family-select-click {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid rgb(227, 18, 121);
  }
  .color1 {
    color: #e60074;
  }
  .color2 {
    color: #e60074;
  }
  .color3 {
    color: #e60074;
  }
  .color4 {
    color: #232e85;
  }
}
@media screen and (max-width: 991px) and (min-width: 576px) {
  .volgras {
    font-size: 15px;
  }

  .bar_content {
    white-space: normal;
    font-size: 13px;
    /* transform: translateX(100%);
    animation: move 15s linear infinite; */
  }
  .smartphone-garanty {
    font-size: 25px !important;
  }
}
.btn-garanty-smartphone {
  font-family: "Century Gothic";
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  padding: 0px 18px;
  text-align: center;
  margin: 0;
}
.simulez-prix {
  font-family: Century Gothic;
  font-size: 31px;
  font-weight: bold;
  color: #000000;
}
.dd{
  color: #fff;
}
.simulezz-prix {
  font-family: Century Gothic;
  font-size: 31px;
  font-weight: bold;
  color: #fff;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  left: -35px;
  top: 35px;
  display: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.title-contact {
  font-size: 25px;
  font-family: "Century Gothic";
  color: #e94475;
  text-align: center;
}
.qts-contact {
  font-size: 15px;
  font-family: "Century Gothic";
  margin-bottom: 15px;
  text-align: center;
}
.text-contact {
  font-size: 18px;
  font-family: "Century Gothic";
  color: #232e85;
  text-align: center;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.connect {
  background-color: #9c69d7;
}

.dropdownNone {
  position: relative;
  left: -217px;
  top: 50px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-content .connect:hover {
  color: #9c69d7;
}

.dropdown1 {
  display: none;
}

.dropdown2 {
  display: none;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.smartphone-garanty {
  font-family: "Century Gothic";
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}
.div-formule {
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 240px;
  margin-bottom: 22px;
  box-shadow: 10pt 10pt 6pt #0000000d;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.div-extend-formule {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 100%;
  border-radius: 8px;
  padding-bottom: 65px;
  padding-top: 65px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fafafa;
  border: 1px solid #000000;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  justify-content: center;
}
.d-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.title-dev-par {
  font-family: "Century Gothic";
  font-weight: bold;
  color: #e94475;
  margin-top: 5%;
}
.mini-card-dev {
  border: 2px solid #e60074;
  border-radius: 10px;
  height: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.num-dev-par {
  position: absolute;
  height: 55px;
  width: 55px;
  background-color: #e60074;
  border-radius: 60px;
  color: #ffffff;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: -36px;
}
.text-align {
  text-align: center;
}
.text-align-P-dev {
  text-align: center;
  color: #192d9c;
  font-family: "Century Gothic";
  font-size: 20px;
}

.div-card-img-dev {
  background-color: white;
  width: 110px;
  height: 110px;
  border-radius: 70px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 3px solid #404fc6;
}
.img-garanty-type {
  max-width: 99%;
}
.menu-mobile {
  display: none;
}

.detail-formule {
  margin-top: 5px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
}

.detail-formule i {
  color: #000000;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none;
}
.seconnecterr1 {
  display: flex;
  justify-content: center;
  background-color: #fc06e7;
  height: 45px;
  width: 90%;
  align-items: center;
  border: 2.5px solid #fc06e7;
  border-radius: 10px;
  cursor: pointer;
}
.img-conx-blanc {
  margin-right: 8px;
  height: 30px;
}
.img-conx-violet {
  margin-right: 8px;
  height: 30px;
  display: none;
}
.text-conx {
  font-family: "Century Gothic";
  font-size: 17px;
  font-weight: bold;
  margin-top: 0;
  color: rgb(252, 6, 231);
}
.seconnecterr1:hover {
  background-color: transparent;
}
.seconnecterr1 .text-conx {
  color: #ffffff;
}
.seconnecterr1:hover .text-conx {
  color: rgb(252, 6, 231);
}
.seconnecterr1:hover .img-conx-violet {
  display: flex;
}
.seconnecterr1:hover .img-conx-blanc {
  display: none;
}

.seconnecterr2 {
  display: flex;
  justify-content: center;
  background-color: #9c69d7;
  height: 45px;
  width: 90%;
  align-items: center;
  border: 3px solid #9c69d7;
  border-radius: 28px;
  cursor: pointer;
}
.form2 {
  flex: 0 0 auto;
  width: 25%;
}
.form1 {
  flex: 0 0 auto;
  width: 40.666667%;
}
.seconnecterr2:hover {
  background-color: transparent;
}

.deconnecter {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  width: 77%;
  height: 40px;
  align-items: center;
  position: absolute;
  border-radius: 12px;
  top: 54px;
  left: 18px;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* menu dropdown */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0.5em 0;
  list-style: none;
  width: max-content;
}

.menu-item {
  padding-bottom: 5px;
  position: relative;
  cursor: pointer;

  color: #fff;
  font-family: "Century Gothic";
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  list-style: none;
}

.menu-item:hover .sub-menu,
.menu-item:hover .sub-menu:hover,
.menu-item:focus-within .sub-menu,
.menu-item:focus-within .sub-menu:hover {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.menu-item:hover .arrow-menu,
.menu-item:hover .arrow-menu:hover,
.menu-item:focus-within .arrow-menu,
.menu-item:focus-within .arrow-menu:hover {
  transform: rotateZ(180deg);
  transition: 0.3s;
}
.arrow-menu {
  transform: rotateZ(0deg);
  transition: 0.3s;
}
.col-lg3 {
  flex: 0 0 auto;
  width: 20%;
}
.socialmedia-div {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
.sub-menu {
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border-radius: 19px;
  transition: 0.5s;
}
.sub-menu .menu-item {
  margin: 10px 25px 5px 20px;
  width: 100%;
  z-index: 2;
}

.text-menu-name {
  margin-left: 0;
  text-align: left;
  font: normal normal normal 16px/24px Century Gothic;
  letter-spacing: 0px;
  color: #c727cc;
}
/* .text-menu-name:hover {
  color: #c727cc !important;
  font: normal normal bold 18px/24px Century Gothic;
} */
/* .text-menu-name:focus {
  outline: none;
} */

@media (max-width: 690px) {
  .menu {
    width: 95%;
    font-size: 16px;
  }

  .menu-item {
    margin: 0.1em;
  }
  .menu-item:nth-child(1) {
    order: 0;
  }
  .menu-item:nth-child(2) {
    order: 1;
  }
  .menu-item:nth-child(3) {
    order: 3;
  }
  .menu-item:nth-child(4) {
    order: 4;
  }
  .menu-item:nth-child(5) {
    order: 2;
  }
}
@media (max-width: 480px) {
  .menu {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (max-width: 767px) {
  .logo-bh{
    margin-left: 0;
    margin-right: 20px;
  }
  .logo-garanty{
    display: flex;
    justify-content: center;
    border-left:none;
    align-items: center;
  }
  .div-img-mult-btn {
    padding: 5%;
  }
  .oui-btn-div {
    width: 100%;
  }
  .non-btn-div {
    width: 100%;
  }
  .form1 {
    flex: 0 0 auto;
    width: 39%;
  }
  .form2 {
    flex: 0 0 auto;
    width: 56%;
  }
  .dispnone {
    display: none;
  }
  .text-formula {
    display: none;
  }
  .form {
    flex: 0 0 auto;
    width: 81%;
  }
  .img-formula-select {
    width: 100%;
  }
  .img-formula {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .dispnone {
    display: none;
  }
  .text-formula {
    display: none;
  }
  .form2 {
    flex: 0 0 auto;
    width: 44%;
  }
  .form1 {
    flex: 0 0 auto;
    width: 25%;
  }
  .form {
    flex: 0 0 auto;
    width: 81%;
  }
  .img-formula-select {
    width: 100%;
  }
  .img-formula {
    width: 100%;
  }
}

@media screen and (max-width: 1160px) {
  .img-couvert {
    width: 182px !important;
  }
}
