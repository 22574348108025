@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: purple;
}
.Toastify__toast--success {
  background: teal;
}
.Toastify__toast--warning {
  background: yellow;
} */
.Toastify__toast--error {
  background: #ffefdc
}

.text-navbar {
  color: black;
  font-family: "Century Gothic";
  font-size: 16px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}

.body-souscrire {
  margin: 0;
  padding: 0;
}
.box-offer-souscrire {
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
  margin-bottom: 3%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  height: 668px;
}
.modifier {
  align-items: center;
  border: 3px solid #dd6446;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #dd6446;
  height: 45px;
  width: 20%;
  margin-left: 60px;
  margin-top: 15px;
}
.modifier:hover {
  background-color: transparent;
}
.text-mod{
  font-family: "Century Gothic";
  font-size: 17px;
  font-weight: bold;
  margin-top: 0;
  color:#FFFFFF
}
.btn-souscrire {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px solid #dd6446;
  border-radius: 50px;
  background-color: #dd6446;
  margin-bottom: 2%;
  cursor: pointer;
}
.btn-protegon {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px solid #ffff;
  border-radius: 50px;
  background-color: #ffff;
  margin-bottom: 2%;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 10%;
}

.input1 {
  /* width: 563px; */
  height: 50px;
  margin-top: 1%;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  opacity: 1;
  padding-left: 10px;
  position: relative;
}

.input2 {
  /* width: 100%; */
  height: 50px;
  margin-top: 1%;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  opacity: 1;
  padding-left: 10px;
  position: relative;
}

::-webkit-input-placeholder {
  text-align: left;
  font: normal normal normal 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #b8b8b8;
  opacity: 1;
}

:-ms-input-placeholder {
  text-align: left;
  font: normal normal normal 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #b8b8b8;
  opacity: 1;
}

::placeholder {
  text-align: left;
  font: normal normal normal 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #b8b8b8;
  opacity: 1;
}

.label {
  padding-left: 5px;
  text-align: left;
  font: normal normal bold 14px/26px Century Gothic;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.img-sous {
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 15px;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 19px;
}
.txt{
  font-size: 20px;
    font-weight: bold;
    font-family: "Century Gothic";
    margin-top: 15px;
}
.txt-p{
  margin-top: 10px;
  font-family: "Century Gothic";
  font-size: 15px;
  padding-right: 36px;
}

* {
  margin: 0;
  padding: 0;
}
.row {
  margin: 0;
  padding: 0;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  justify-content: space-between;
}
.text-popup {
  font-size: 15px !important;
  padding-left: 5px;
  font-family: "Century Gothic" !important;
  font-weight: bold;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.text-center {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sim-text-div-right {
  background-color: #393c8f;
  padding-left: 10%;
  padding-right: 2%;
}
.sim-text-div-left {
  background-color: rgb(65 68 157);
  padding-right: 10%;
  padding-left: 2%;
}
.text-sous-sim {
  font-family: "Century Gothic";
  font-size: 20px;
  color: white;
  margin-top: 2%;
  margin-bottom: 6%;
}
.dev-par-card1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ed2d87;
  padding: 28px;
  border-radius: 10px;
}
.dev-par-card3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  padding: 28px;
  border-radius: 10px;
  box-shadow: #dee2e6 0pt 0pt 5pt 0px;
  height: 100%;
}
.pp {
  color: #ffffff;
  font-family: "Century Gothic";
  font-size: 25px;
  font-weight: bold;
}
.sous-status {
  flex: 0 0 auto;
  width: 25%;
  padding: 20px;
}
.status {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
  flex: 0 0 auto;
  width: 66%;
}
.garantie-contre {
  display: flex;
  margin-bottom: 2%;
  justify-content: center;
  flex: 0 0 auto;
  width: 60%;
}
.sous-garantie-contre {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
  width: 25%;
}
.simula-div{
  background-color: #e31279;
  border-radius: 21px;
  border: 1px solid #fff;
  margin: 16px;
}
.part-switch-prod-div {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  justify-content: center;
}
.part-switch-prod {
  flex: 0 0 auto;
  width: 25%;
  padding: 5px;
}
.dev-par-card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #243987;
  padding: 28px;
  border-radius: 10px;
}
.text-dev-card-arabe {
  color: white;
  font-size: 28px;
}
.title-sim-text {
  font-family: "Century Gothic";
  font-weight: bold;
  font-size: 23px;
  margin-top: 15px;
}
.header-container-slidePartenaire {
  width: 100%;
  height: 70vh;
  background-image: url(/static/media/slider-devenir-partenaire.d608fae1.jpg);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slideContact {
  width: 100%;
  height: 60vh;
  background-image: url(/static/media/sliderContact.459ea335.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.header-container-slide1 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web2.png"); */
  background-image: url(/static/media/mobile.77d476ea.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.header-container-slidee00 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BannerMeuble.png");
   */
   background-image: url(/static/media/meubles.bd66a279.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee0 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie.png"); */
  background-image: url(/static/media/carte-x-garanty.20e4bd70.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee1 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web2.png"); */
  background-image: url(/static/media/mobile.77d476ea.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide2 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 5.png"); */
  background-image: url(/static/media/electro.6e473f4c.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee2 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 5.png"); */
  background-image: url(/static/media/electro.6e473f4c.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide3 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 8.png"); */
  background-image: url(/static/media/pc.118b724b.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee3 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 8.png"); */
  background-image: url(/static/media/pc.118b724b.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slide4 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 10.png"); */
  background-image: url(/static/media/pc.118b724b.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee4 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 10.png"); */
  background-image: url(/static/media/optique.debcf8bd.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee5 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/banner-web.png"); */
  background-image: url(/static/media/smartwatch.9ec70d10.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee6 {
  width: 100%;
  height: 83vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 15.png"); */
  background-image: url(/static/media/télévision.01958451.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee7 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 3.png"); */
  background-image: url(/static/media/summer-9.deb0a9d4.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee8 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 16.png"); */
  background-image: url(/static/media/summer-1.79ec2e53.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee9 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 17.png"); */
  background-image: url(/static/media/summer-2.bc9e3458.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee10 {
  width: 100%;
  height: 80vh;
  background-image: url("/static/media/BanniereSitewebAffichage_Plan de travail 1 copie 18.734f3bd3.png");
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee23{
  width: 100%;
  height: 81vh;
  background-image: url(/static/media/AssuranceOptiqueMaladie.00a9425b.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.header-container-slidee11 {
  width: 100%;
  height: 80vh;
  /* background-image: url("./BannerFullSize/BanniereSitewebAffichage_Plan de travail 1 copie 12.png"); */
  background-image: url(/static/media/summer-4.77a5ea4e.png);
  margin: 0;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.garanty-logo {
  display: grid;
  justify-content: end;
  align-content: center;
  padding-right: calc(var(--bs-gutter-x) * 1.5);
}
.assurance-logo {
  display: grid;
  justify-content: start;
  align-content: center;
}
.logo-bh{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  margin-left: 60px;
}
.logo-garanty{
  display: flex;
  justify-content: center;
  border-left: 2px solid #aeaeae;
  align-items: center;
  margin-left: 6px;
}

.borde1 {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 80px;
  width: 300px;
  border-right: 1px solid #f9fafb;
  margin-left: 20px;
  margin-bottom: 20px;
}
.carousel-control-next, .carousel-control-prev {
  width: 7% !important;
}
.borde2 {
  height: auto;
  width: 300px;
  /* margin-left: 20px; */
  margin-bottom: 20px;
  display: grid;
  justify-content: start;
  align-items: center;
  justify-items: start;
}
.text-menu {
  color: #fff;
  font-family: "Century Gothic";
  font-size: 17px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  margin-left: 0;
  font-weight: bold;
}
.menu-text-dropdown {
  color: #000000;
  font-family: "Century Gothic";
  font-size: 15px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}
.div-text-slide {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  flex: 0 0 auto;
  width: 50%;
}

.connect-btn1 {
  width: 20%;
  border-radius: 10px;
  background-color: #c827cb;
  font-family: "Century Gothic";
  color: #fff;
}
.produit {
  display: flex;
  justify-content: flex-end;
}

.apropos {
  display: flex;
  justify-content: center;
}

.aide {
  display: flex;
  justify-content: start;
}

.fa-check {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-times {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-align-P-dev1 {
  text-align: center;
  color: #192d9c;
  font-family: "Century Gothic";
  font-size: 20px;
}
.form-div {
  width: 20%;
  padding-left: calc(var(--bs-gutter-x) * 0.3);
  padding-right: calc(var(--bs-gutter-x) * 0.3);
  padding-bottom: calc(var(--bs-gutter-x) * 0.3);
}
.form-div-S {
  width: 33%;
  padding-left: calc(var(--bs-gutter-x) * 3);
  padding-right: calc(var(--bs-gutter-x) * 3);
  padding-bottom: calc(var(--bs-gutter-x) * 3);
}
.oui-btn-div {
  padding-right: 5px;
}
.non-btn-div {
  padding-left: 5px;
}
.bar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  font-family: "Century Gothic";
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}
.contain {
  display: grid;
  align-content: center;
}
.garanty-switch2 {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 90px;
  margin-bottom: 60px;
}
.garanty-switch1 {
  display: flex;
  justify-content: center;
  align-content: center;
}
.tabset-icon {
  margin-top: -60px;
}
.dispnone {
  display: flex;
}
.garanty-switch {
  display: flex;
  align-content: center;
  border: 1px solid #fafafa;
  cursor: pointer;
  background-color: #fafafa;
  box-shadow: rgb(0 0 0 / 5%) 4pt 4pt 4pt;
  border-radius: 12px;
  height: 160px;
  align-items: center;
  margin-top: -28px;
  flex-direction: column;
  padding-bottom: 6%;
  padding-top: 6%;
  justify-content: space-between;
}

.smartphone-body {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  margin-bottom: 10%;
}

.garantie-Smart {
  margin-bottom: 5%;
}
.garantie-Smart1 {
  margin-bottom: 3%;
}
.simulation-prix {
  margin-bottom: 4%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.btn-hold {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.btn-decouvrezGarantie {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #4f46a0;
  border-radius: 11px;
  background: linear-gradient(#fd8cc4, #e31279);
  color: #fff;
  cursor: pointer;
}
.simulation-input {
  display: flex;
  justify-content: center;
}

.input-prix {
  height: 50px;
  border-radius: 7px;
  width: 100%;
  border: 1px solid #fafafa;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  padding-left: 15px;
  position: relative;
  background-color: #fafafa;
}

.calcule-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #4f46a0;
  border-radius: 7px;
  background: linear-gradient(#6e65be, #372884);
  justify-content: center;
  font-family: "Century Gothic";
  font-size: "19px";
  font-weight: "bold";
  color: #fff;
}
.elec-fam {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.container-footer {
  display: flex;
  justify-content: space-evenly;
}
.div-foot {
  padding: 10px;
  margin-top: 30px;
}
.img-formula {
  width: 62%;
  -webkit-filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
          filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.img-formula-select {
  width: 62%;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.img-fam-mult-cla {
  width: 115px;
  -webkit-filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
          filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
}
.img-fam-mult {
  width: 115px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.img-fam-mult-cla-tab {
  width: 75px;
  -webkit-filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
          filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(156deg)
    brightness(10%);
}
.img-fam-mult-tab {
  width: 75px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.txt-img-mult-fam {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #242e87;
}
.txt-img-mult-fam-hover {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.txt-img-elec-fam {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #e60074;
  text-decoration: underline;
}
.txt-img-elec-fam-hover {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #242e87;
  text-decoration: underline;
}

.text-couvert-mut-div {
  font-family: "Century Gothic";
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 35px;
}

.img-mult-covert {
  flex-direction: column;
  padding: 15px;
  position: relative;
  flex: 0 0 auto;
  width: 20%;
}
.div-mult-fam-btn {
  flex: 0 0 auto;
  width: 50%;
}
.div-img-mult-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(36, 46, 135);
  padding-bottom: 4%;
  padding-top: 5%;
  border-radius: 20px;
  cursor: pointer;
}

.formule-offer {
  display: flex;
  justify-content: center;
}

.img-couvert {
  width: 235px;
}
.img-couvert-eclectro {
  width: 80px;
}

.text-img-couvert {
  font-family: "Century Gothic";
  font-size: 20px;
  font-weight: bold;
  margin-top: 4%;
  white-space: nowrap;
  text-align: center;
  color: #393c8f;
}

.box-offer {
  border: 3px solid;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 15%;
  padding-bottom: 15%;
  margin-bottom: 20%;
}
.marque-pop {
  display: flex;
  justify-content: center;
  background-color: #9285d7;
  height: 190px;
}
.img-formule {
  min-width: 173px;
}
.img-formule-extend {
  max-width: 255px;
}
h6 {
  margin-bottom: 0rem;
  margin-top: 0.3rem;
}
h5 {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.div-family-select-click {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-family-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* //////////////////////////////////////// */

.triplebar {
  display: inline-block;
  cursor: pointer;
}
.garantie {
  width: 85%;
}
.garantie1 {
  width: 75%;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 4px;
  background-color: #000000;
  margin: 6px 0;
  transition: 0.4s;
}
.textSize-slide-header {
  font-family: "Century Gothic";
  font-size: 70px;
  font-weight: bold;
}
.textSize-slide-header1 {
  font-size: 50px;
  font-family: "Century Gothic";
  font-weight: bold;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.btn-3 {
  font-family: "Century Gothic";
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 0px 18px;
  text-align: center;
}
.nav-menu {
  position: relative;
  z-index: 1000;
}
.text-granty-type {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.color1 {
  color: #ffffff;
}
.color2 {
  color: #e60074;
}
.color3 {
  color: rgb(35 46 133);
}
.color4 {
  color: #ffffff;
}
.d-sous-a-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formula {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #000000;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  height: 86px;
  margin-top: 17px;
  background-color: #fafafa;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}
.formula-select {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #000000;
  height: 86px;
  margin-top: 17px;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(#fd8cc4, #e31279);
  cursor: pointer;
}
.text-formula {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.formula-bloc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: 0 0 auto;
  width: 100%;
}
.formule-bloc {
  flex: 0 0 auto;
  width: 33.33333333%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 6%;
}
.form {
  flex: 0 0 auto;
  width: 50%;
}
.none {
  display: flex;
}
.headweb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  width: 33.333333%;
}
.CNDA {
  padding-right: 100px;
}
.question-souscription {
  background-color: #f3ebff;
  height: 160px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.barvol {
  top: 0;
  left: 0;
  padding: 10px 0;
  background: #d9002b;
  color: white;
  text-align: center;
}
.volgras {
  color: white;
  font-size: 25px;
  font-weight: bold;
  font-family: "system-ui";
}

.bar_content {
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: bold;
  /* transform: translateX(100%);
  animation: move 15s linear infinite; */
}

/* @keyframes move {
  to {
    transform: translateX(-80%);
  }
} */
@media screen and (max-width: 760px) {
  .elec-fam {
    flex-direction: column;
  }
}
@media screen and (max-width: 1500px) {
  .div-mult-fam-btn {
    width: 65%;
  }
  .none {
    display: none;
  }
  .status {
    width: 75%;
  }
  .garantie-contre {
    width: 68%;
  }
}
@media screen and (max-width: 1220px) {
  .volgras {
    font-size: 20px;
  }

  .bar_content {
    font-size: 18px;
    /* transform: translateX(100%);
    animation: move 15s linear infinite; */
  }
  .div-mult-fam-btn {
    width: 73%;
  }
  .garantie-contre {
    width: 73%;
  }
  .part-switch-prod-div {
    width: 75%;
  }
  .mobile-menu-toggler {
    position: absolute;
    left: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .contain {
    display: none;
  }
  .triplebar {
    display: block;
  }
  .garanty-switch {
    margin-top: -14px;
  }
  .garanty-switch2 {
    margin-top: 90px;
    margin-bottom: 60px;
  }
  .garantie {
    width: 100%;
    margin-left: 12px;
  }
  .garantie1 {
    width: 100%;
    margin-right: 10px;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    height: 95px;
  }

  .menu-mobile {
    display: flex !important;
    justify-content: center;
    margin-left: -29px;
  }
  .contain2 {
    display: flex;
    flex: 0 0 auto;
    width: 23.333333%;
  }
  .headweb {
    width: 65.333333%;
    margin-left: 2%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
.div-btn-calculate {
  justify-content: flex-start;
}
@media screen and (max-width: 1880px) and (min-width: 991px) {
  .form-div-S {
    width: 30%;
    padding-left: calc(var(--bs-gutter-x) * 2);
    padding-right: calc(var(--bs-gutter-x) * 2);
    padding-bottom: calc(var(--bs-gutter-x) * 2);
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media screen and (max-width: 1250px) {
  .garanty-switch {
    justify-content: center;
    height: 90px;
  }
  .text-granty-type {
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .text-couvert-mut-div {
    font-size: 24px;
  }
  .formule-bloc {
    width: 50%;
  }
  .formula-bloc {
    flex-direction: column;
  }
  .socialmedia-div {
    flex-direction: column;
    align-items: center;
  }
  .col-lg3 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .div-mult-fam-btn {
    width: 87%;
  }
  .img-mult-covert {
    width: 25%;
  }
  .garantie-contre {
    width: 68%;
    flex-direction: column;
    align-items: center;
  }
  .sous-garantie-contre {
    width: 50%;
    margin-top: 3%;
  }
  .sous-status {
    width: 70%;
  }
  .status {
    flex-direction: column;
  }
  .form-div {
    width: 33%;
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    padding-right: calc(var(--bs-gutter-x) * 0.3);
  }
  .form-div-S {
    width: 30%;
    padding-left: calc(var(--bs-gutter-x) * 0.3);
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .contain2 {
    display: block;
  }
  .nav-menu {
    width: 100%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
  .div-mult-fam-btn {
    width: 80%;
  }
  .img-mult-covert {
    width: 45%;
  }
  .sous-status {
    width: 70%;
  }
  .status {
    flex-direction: column;
  }
  .form-div {
    width: 50%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .form-div-S {
    width: 50%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .textSize-slide-header {
    font-size: 50px;
  }
  .textSize-slide-header1 {
    font-size: 35px;
  }
}
@media screen and (min-width: 768px) {
  .simulation-input {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 640px) {
  .txt-img-mult-fam {
    font-size: 15px;
  }
  .txt-img-mult-fam-hover {
    font-size: 15px;
  }
  .img-fam-mult-cla-tab {
    width: 50px;
  }
  .img-fam-mult-tab {
    width: 50px;
  }
  .img-fam-mult-cla {
    width: 90px;
  }
  .img-fam-mult {
    width: 90px;
  }
}

@media screen and (max-width: 767px) {
  .part-switch-prod-div {
    flex-direction: column;
    align-items: center;
  }
  .part-switch-prod {
    width: 80%;
  }
  .question-souscription {
    display: none;
  }
  .garantie-contre {
    width: 68%;
    flex-direction: column;
    align-items: center;
  }
  .sous-garantie-contre {
    width: 50%;
    margin-top: 3%;
  }
  .d-sous-a-c {
    display: none;
    justify-content: center;
    align-items: center;
  }
  .simulation-input {
    height: 235px;
  }
  .sim-text-div-left {
    padding-left: 10%;
  }
  .div-btn-calculate {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 1220px) {
  .triplebar {
    display: none;
  }
  .contain2 {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .txt-img-mult-fam {
    font-size: 12px;
    white-space: nowrap;
  }
  .txt-img-mult-fam-hover {
    font-size: 12px;
    white-space: nowrap;
  }
  .img-fam-mult-cla-tab {
    width: 30px;
  }
  .img-fam-mult-tab {
    width: 30px;
  }
  .img-fam-mult-cla {
    width: 50px;
  }
  .img-fam-mult {
    width: 50px;
  }
  .img-mult-covert {
    width: 51%;
  }
  .sous-status {
    width: 100%;
  }
  .status {
    flex-direction: column;
  }
  .header-container {
    height: 500px;
  }
  .smartphone-garanty {
    font-size: 23px !important;
  }
  .btn-garanty-smartphone {
    font-size: 16px !important;
  }
  .simulez-prix {
    font-size: 24px !important;
  }
  .simulezz-prix {
    font-size: 24px !important;
  }
  .form-div {
    width: 100%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .form-div-S {
    width: 100%;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-bottom: calc(var(--bs-gutter-x) * 3);
  }
  .formule-bloc {
    width: 70%;
  }
  .textSize-slide-header {
    margin-top: 50%;
    font-size: 50px;
  }
  .textSize-slide-header1 {
    font-size: 35px;
  }
  .barvol {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    /* display: none; */
  }

  .volgras {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  /* @keyframes move {
    to {
      transform: translateX(-200%);
    }
  } */
  .bar_content {
    white-space: normal;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
  }
  .header-container-slidee0 {
    background-image: url(/static/media/garanty-carte.1eb37144.png);
    height: 28vh;
  }
  .header-container-slidee1 {
    background-image: url(/static/media/meuble.c4950c35.png);
    height: 28vh;
  }
  .CNDA {
    padding-right: 0;
  }
  /* .header-container-slide2 {
    background-image: url("./slide2mobile.jpg");
    height: 60vh;
  } */
  .header-container-slidee2 {
    background-image: url(/static/media/optique.ca98ecd1.png);
    height: 28vh;
  }
  .btn-wrapper {
    display: inline-grid;
    margin-bottom: 20px;
  }
  .header-container-slidee00{
    background-image: url(/static/media/mobile.f1fa090e.png);
    height: 28vh;
  }
  .header-container-slidee3 {
    background-image: url(/static/media/pc.511925bb.png);
    height: 28vh;
  }
  .header-container-slidee11{
    background-image: url(/static/media/smart.2de79c4d.png);
    height: 28vh;
  }
  .header-container-slidee4 {
    background-image: url(/static/media/electro.6514e10d.png);
    height: 28vh;
  }
  .header-container-slidee5 {
    background-image: url(/static/media/summer-banner-mobile10.1967c060.png);
    height: 28vh;
  }
  .header-container-slidee6 {
    background-image: url(/static/media/Télévision.71d6cdc6.png);
    height: 28vh;
  }
  .header-container-slidee7 {
    background-image: url(/static/media/summer-banner-mobile9.2e61e09a.png);
    height: 28vh;
  }
  .header-container-slidee8 {
    background-image: url(/static/media/summer-banner-mobile1.37207d21.png);
    height: 28vh;
  }
  .header-container-slidee9 {
    background-image: url(/static/media/summer-banner-mobile2.40b912f9.png);
    height: 28vh;
  }
  .header-container-slidee10 {
    background-image: url(/static/media/BanniereSitewebAffichage-32.6d665a1c.png);
    height: 28vh;
  }

  .text-align-P-dev1 {
    font-size: 16px;
  }

  .div-text-slide {
    flex: 0 0 auto;
    width: 100%;
  }
  .img-garanty-type {
    margin-bottom: 3px;
  }
  .div-family-select-click {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid rgb(227, 18, 121);
  }
  .color1 {
    color: #e60074;
  }
  .color2 {
    color: #e60074;
  }
  .color3 {
    color: #e60074;
  }
  .color4 {
    color: #232e85;
  }
}
@media screen and (max-width: 991px) and (min-width: 576px) {
  .volgras {
    font-size: 15px;
  }

  .bar_content {
    white-space: normal;
    font-size: 13px;
    /* transform: translateX(100%);
    animation: move 15s linear infinite; */
  }
  .smartphone-garanty {
    font-size: 25px !important;
  }
}
.btn-garanty-smartphone {
  font-family: "Century Gothic";
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  padding: 0px 18px;
  text-align: center;
  margin: 0;
}
.simulez-prix {
  font-family: Century Gothic;
  font-size: 31px;
  font-weight: bold;
  color: #000000;
}
.dd{
  color: #fff;
}
.simulezz-prix {
  font-family: Century Gothic;
  font-size: 31px;
  font-weight: bold;
  color: #fff;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  left: -35px;
  top: 35px;
  display: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.title-contact {
  font-size: 25px;
  font-family: "Century Gothic";
  color: #e94475;
  text-align: center;
}
.qts-contact {
  font-size: 15px;
  font-family: "Century Gothic";
  margin-bottom: 15px;
  text-align: center;
}
.text-contact {
  font-size: 18px;
  font-family: "Century Gothic";
  color: #232e85;
  text-align: center;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.connect {
  background-color: #9c69d7;
}

.dropdownNone {
  position: relative;
  left: -217px;
  top: 50px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-content .connect:hover {
  color: #9c69d7;
}

.dropdown1 {
  display: none;
}

.dropdown2 {
  display: none;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.smartphone-garanty {
  font-family: "Century Gothic";
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}
.div-formule {
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 240px;
  margin-bottom: 22px;
  box-shadow: 10pt 10pt 6pt #0000000d;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.div-extend-formule {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 100%;
  border-radius: 8px;
  padding-bottom: 65px;
  padding-top: 65px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fafafa;
  border: 1px solid #000000;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  justify-content: center;
}
.d-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.title-dev-par {
  font-family: "Century Gothic";
  font-weight: bold;
  color: #e94475;
  margin-top: 5%;
}
.mini-card-dev {
  border: 2px solid #e60074;
  border-radius: 10px;
  height: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.num-dev-par {
  position: absolute;
  height: 55px;
  width: 55px;
  background-color: #e60074;
  border-radius: 60px;
  color: #ffffff;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: -36px;
}
.text-align {
  text-align: center;
}
.text-align-P-dev {
  text-align: center;
  color: #192d9c;
  font-family: "Century Gothic";
  font-size: 20px;
}

.div-card-img-dev {
  background-color: white;
  width: 110px;
  height: 110px;
  border-radius: 70px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 3px solid #404fc6;
}
.img-garanty-type {
  max-width: 99%;
}
.menu-mobile {
  display: none;
}

.detail-formule {
  margin-top: 5px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
}

.detail-formule i {
  color: #000000;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none;
}
.seconnecterr1 {
  display: flex;
  justify-content: center;
  background-color: #fc06e7;
  height: 45px;
  width: 90%;
  align-items: center;
  border: 2.5px solid #fc06e7;
  border-radius: 10px;
  cursor: pointer;
}
.img-conx-blanc {
  margin-right: 8px;
  height: 30px;
}
.img-conx-violet {
  margin-right: 8px;
  height: 30px;
  display: none;
}
.text-conx {
  font-family: "Century Gothic";
  font-size: 17px;
  font-weight: bold;
  margin-top: 0;
  color: rgb(252, 6, 231);
}
.seconnecterr1:hover {
  background-color: transparent;
}
.seconnecterr1 .text-conx {
  color: #ffffff;
}
.seconnecterr1:hover .text-conx {
  color: rgb(252, 6, 231);
}
.seconnecterr1:hover .img-conx-violet {
  display: flex;
}
.seconnecterr1:hover .img-conx-blanc {
  display: none;
}

.seconnecterr2 {
  display: flex;
  justify-content: center;
  background-color: #9c69d7;
  height: 45px;
  width: 90%;
  align-items: center;
  border: 3px solid #9c69d7;
  border-radius: 28px;
  cursor: pointer;
}
.form2 {
  flex: 0 0 auto;
  width: 25%;
}
.form1 {
  flex: 0 0 auto;
  width: 40.666667%;
}
.seconnecterr2:hover {
  background-color: transparent;
}

.deconnecter {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  width: 77%;
  height: 40px;
  align-items: center;
  position: absolute;
  border-radius: 12px;
  top: 54px;
  left: 18px;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* menu dropdown */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0.5em 0;
  list-style: none;
  width: -webkit-max-content;
  width: max-content;
}

.menu-item {
  padding-bottom: 5px;
  position: relative;
  cursor: pointer;

  color: #fff;
  font-family: "Century Gothic";
  font-size: 15px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  list-style: none;
}

.menu-item:hover .sub-menu,
.menu-item:hover .sub-menu:hover,
.menu-item:focus-within .sub-menu,
.menu-item:focus-within .sub-menu:hover {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.menu-item:hover .arrow-menu,
.menu-item:hover .arrow-menu:hover,
.menu-item:focus-within .arrow-menu,
.menu-item:focus-within .arrow-menu:hover {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
  transition: 0.3s;
}
.arrow-menu {
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  transition: 0.3s;
}
.col-lg3 {
  flex: 0 0 auto;
  width: 20%;
}
.socialmedia-div {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
.sub-menu {
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border-radius: 19px;
  transition: 0.5s;
}
.sub-menu .menu-item {
  margin: 10px 25px 5px 20px;
  width: 100%;
  z-index: 2;
}

.text-menu-name {
  margin-left: 0;
  text-align: left;
  font: normal normal normal 16px/24px Century Gothic;
  letter-spacing: 0px;
  color: #c727cc;
}
/* .text-menu-name:hover {
  color: #c727cc !important;
  font: normal normal bold 18px/24px Century Gothic;
} */
/* .text-menu-name:focus {
  outline: none;
} */

@media (max-width: 690px) {
  .menu {
    width: 95%;
    font-size: 16px;
  }

  .menu-item {
    margin: 0.1em;
  }
  .menu-item:nth-child(1) {
    order: 0;
  }
  .menu-item:nth-child(2) {
    order: 1;
  }
  .menu-item:nth-child(3) {
    order: 3;
  }
  .menu-item:nth-child(4) {
    order: 4;
  }
  .menu-item:nth-child(5) {
    order: 2;
  }
}
@media (max-width: 480px) {
  .menu {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (max-width: 767px) {
  .logo-bh{
    margin-left: 0;
    margin-right: 20px;
  }
  .logo-garanty{
    display: flex;
    justify-content: center;
    border-left:none;
    align-items: center;
  }
  .div-img-mult-btn {
    padding: 5%;
  }
  .oui-btn-div {
    width: 100%;
  }
  .non-btn-div {
    width: 100%;
  }
  .form1 {
    flex: 0 0 auto;
    width: 39%;
  }
  .form2 {
    flex: 0 0 auto;
    width: 56%;
  }
  .dispnone {
    display: none;
  }
  .text-formula {
    display: none;
  }
  .form {
    flex: 0 0 auto;
    width: 81%;
  }
  .img-formula-select {
    width: 100%;
  }
  .img-formula {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .dispnone {
    display: none;
  }
  .text-formula {
    display: none;
  }
  .form2 {
    flex: 0 0 auto;
    width: 44%;
  }
  .form1 {
    flex: 0 0 auto;
    width: 25%;
  }
  .form {
    flex: 0 0 auto;
    width: 81%;
  }
  .img-formula-select {
    width: 100%;
  }
  .img-formula {
    width: 100%;
  }
}

@media screen and (max-width: 1160px) {
  .img-couvert {
    width: 182px !important;
  }
}

.body-inscrit {
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}
.fb {
  position: absolute;
  background-color: transparent;
  border: calc(0.06887vw + 0.67769px) solid transparent;
  padding: calc(0.34435vw + 18.38843px) calc(0.64435vw + 35.38843px);
  margin-top: -24px;
  margin-left: -60px;
  width: 311px;
}
.fb2{
  margin-right: 14px;
}
.login {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  border-color: #b8b8b8;
  padding-left: 15px;
}
.checkbox-div {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

/* Hide the browser's default checkbox */
.checkbox-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid;
}

/* On mouse-over, add a grey background color */
.checkbox-div:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-div input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.connect-btn {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #4f46a0;
  border-radius: 5px;
  background: linear-gradient(#6e65be, #372884);
  justify-content: space-evenly;
  font-family: "Century Gothic";
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.col-lg3 {
  flex: 0 0 auto;
  width: 20%;
}
@media screen and (max-width: 1050px) {
  .col-lg3 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .col-lg3 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.div-icon-steps {
  width: 70px;
  height: 70px;
  background-color: #D4BFF4;
  border-radius: 100px;
}
.icon-steps {
  height: 35px;
  width: 35px;
}
.flex-d-c{
  flex-direction: column;
}
hr{
  position: absolute;
    top: 35px;
    margin: 0 50px;
    width: 72% ! important;
    z-index: -1;
    height: 5px ! important;
    color: #D4BFF4;
}
.input-radio{
  display:flex;
  margin-top: 60px;
}
.form-check-input:checked {
  background-color: #AA74E8;
  border-color: #AA74E8;
}
.form-check-label{
  text-align: center;
  font: bold 16px / 24px "Century Gothic";
  color: rgb(0, 0, 0);
}
.marginTop40{
  margin-top: 40px;
}
.marginTop20{
  margin-top: 20px;
}
.check-input{
  margin-top: 30px;
}
.code-envoie{
text-decoration: underline;
font: normal normal normal 12px/26px Century Gothic;
color: #C727CC;
margin-top:20px;
}

* {
  margin: 0;
  padding: 0;
}

.specialiste-ass {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 60px;
  margin-bottom: 10px;
}

.spec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spec1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aventage-Garantie {
  background-color: #0c015f;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.sinistre-garanty {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.partenaires {
  background: linear-gradient(#fd8cc4, #e31279);
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.newsletter {
  background-color: #0c015f;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.HomePageFooter {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.HomePageFooter-container {
  margin-top: 4%;
  margin-bottom: 4%;
}

.container-fluide {
  display: contents;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.img-arrow {
  width: 25px;
}
.div-arrow {
  display: flex;
  padding-left: 15px;
}
.gr-container {
  margin-top: 2%;
  margin-bottom: 1%;
}
.gr-container1 {
  margin-top: 3%;
  margin-bottom: 3%;
}

.newsletter-container {
  margin-top: 3%;
  margin-bottom: 6%;
}

.text-0 {
  text-align: center;
  color: #fff;
  margin-bottom: 4%;
  font-family: "Century Gothic";
  font-size: 40px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
}

.text-1 {
  text-align: center;
  margin-bottom: 5%;
  font-family: "Century Gothic";
  font-size: 35px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 0.9;
}

.text-2 {
  text-align: center;
  color: #fff;
  margin-bottom: 3%;
  font-family: "Century Gothic";
  font-size: 20px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
}

.newletter-input {
  background: #e9e6e6;
  height: 50px;
  border: transparent;
  border-radius: 10px;
  font-family: "Century Gothic";
  width: 99%;
}

.newletter-btn {
  background: linear-gradient(rgb(253, 140, 196), rgb(227, 18, 121));
  height: 50px;
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  width: -webkit-fill-available;
  border: transparent;
  border-radius: 10px;
}
.bloc-marche {
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  border-radius: 15px;
  padding: 40px 15px 0 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fafafa;
}
.parg-marche {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Century Gothic";
}
.text-font {
  color: #fff;
  font-family: "Century Gothic";
  font-size: 50px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 0.9;
}

.text-font1 {
  font-family: "Century Gothic";
  font-size: 24px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
  margin-top: 5%;
  text-align: end;
}

.text-font3 {
  font-family: "Century Gothic";
  font-size: 24px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
  margin-top: 20%;
}

.text-font2 {
  font-family: "Century Gothic";
  font-size: 23px;
  text-align: center;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 26.4px;
  margin-top: 3%;
  margin-bottom: 2%;
  color: #ffffff;
  white-space: nowrap;
}

.color-font-gra {
  color: #fff;
  font-family: "Century Gothic";
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
}

.color-font-sini {
  font-family: "Century Gothic";
  font-size: 25px;
  text-align: center;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 30.4px;
  color: #ffffff;
}

.color-font-sini1 {
  font-family: "Century Gothic";
  font-size: 14px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20.4px;
  color: #023a78;
  text-align: end;
}

.color-font-Footer {
  font-family: "Century Gothic";
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

.color-font-title {
  font-family: "Century Gothic";
  color: #000;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 26.4px;
  font-size: 22px;
}

.color-font-HomePageFooter {
  font-size: 17px;
  font-family: "Century Gothic";
  line-height: 1.7;
  white-space: nowrap;
  margin-top: 9px;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.center {
  justify-content: center;
}

.img {
  margin-top: 5%;
  display: grid;
  justify-content: center;
}

.margin {
  margin-top: 2%;
}
.img-slide {
  width: 250px;
}
.prg {
  background-color: #0c015f;
  margin-top: 10%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6%;
}
.footer-carossel .carousel-indicators {
  position: absolute;
  right: 60%;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: -2rem;
  margin-left: 15%;
  list-style: none;
}
/* .carousel-indicators .active {
  opacity: 1;
} */
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #001462;
}
.footer-carossel .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 8px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
}
.text-rapide {
  color: #000000;
  font-family: "Century Gothic";
  font-size: 22px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: 1.5;
  margin-bottom: 6%;
}

.steppr-user {
  display: flex;
  justify-content: center;
}
/* ///////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 767px) {
  .title-footer {
    font-size: 24px !important;
  }
  .text-font {
    font-size: 40px !important;
  }
  .img-avantage {
    max-width: 80% !important;
  }
  .line {
    display: none !important;
  }

  .steps {
    position: absolute;
  }
  .img-footer-garanty {
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .steps {
    position: static;
    position: initial;
  }
}

@media screen and (max-width: 767px) {
  .steppr-user {
    display: none;
  }
  .simulation-btn {
    padding: 0 20px;
    /* width: initial !important; */
    margin-top: 20px;
  }
  .proteger-footer {
    text-align: center;
  }
  .newletter-btn {
    width: auto !important;
    width: initial !important;
    margin-top: 20px;
    float: right;
  }
  .newletter-input {
    width: 100% !important;
  }
  .title-footer1 {
    font-size: 28px !important;
  }
  /* .container-menu-footer {
    display: flex;
    flex-direction: column;
  } */
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .text-font2 {
    white-space: normal !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 575px) {
  .padding0-90 {
    padding: 0px 90px;
  }
}

@media screen and (max-width: 575px) {
  .text-font2 {
    white-space: normal !important;
  }
  .padding20-40 {
    padding: 0 !important;
  }
  .menu-footer {
    flex-direction: column;
    padding-left: 50px;
  }
}

.padding0-90 {
  padding: 0px 10px;
}

.steps {
  top: 40px;
  right: 100px;
}

.img-avantage1 {
  max-width: 400px;
}
.img-avantage {
  max-width: 300px;
}
.img-avantage2 {
  max-width: 314px;
}
.title-footer {
  font-family: Century Gothic;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  text-align: center;
  margin-bottom: 100px;
}
.title-footer1 {
  font-family: Century Gothic;
  font-weight: bold;
  font-size: 22px;
  color: #ec047e;
  margin-bottom: 10%;
}
.d-flex-j {
  display: flex;
  justify-content: center;
}
.d-flex-a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-f {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.width100 {
  width: 100%;
}
.widht50 {
  width: 50%;
}
.widht70 {
  width: 70%;
}
.width95 {
  width: 85%;
}
.max-widhth99 {
  margin-top: 50px;
  max-width: 380px;
}
.padding {
  padding: 20px 40px;
}
.padding1 {
  padding-left: 120px;
}
.margin0 {
  margin: 0;
}
.img-assurance-footer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2%;
  justify-content: center;
}
.padding20-40 {
  padding: 20px 40px;
}
.img-gras-footer {
  display: flex;
  align-items: end;
  justify-content: center;
}
.num-stepperrr {
  position: absolute;
  height: 65px;
  width: 65px;
  background-color: #dfe4f8;
  border-radius: 60px;
  color: #001462;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: -36px;
}
.form3 {
  flex: 0 0 auto;
  width: 18.66666%;
}
.form4 {
  flex: 0 0 auto;
  width: 25%;
}
.div-proteger {
  padding-left: 90px;
  padding-right: 90px;
}
.div-img-proteger {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div-button-simu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.carro-div {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.div-FAQ {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
}
.div-arrow-FAQ {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  margin-top: 8px;
  cursor: pointer;
}
.img-arrow-faq-show {
  width: 25px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
          filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.img-arrow-faq-hide {
  width: 25px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
          filter: invert(61%) sepia(11%) saturate(15%) hue-rotate(348deg)
    brightness(87%) contrast(86%);
}
.proteger-aimez {
  margin-top: 30px;
  margin-bottom: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  position: absolute;
  top: 140px;
  bottom: 150px;
  left: 50%;
  border-left: 1px dashed black;
  border-left: 3px dashed #0000001a;
  width: 1px;
}
.simulation-btn {
  background: linear-gradient(rgb(253, 140, 196), rgb(227, 18, 121));
  height: 50px;
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  width: -webkit-fill-available;
  border: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.proteger-footer {
  font-size: 20px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 3%;
  color: white;
}
::-webkit-input-placeholder {
  padding-left: 10px;
}
:-ms-input-placeholder {
  padding-left: 10px;
}
::placeholder {
  padding-left: 10px;
}
.img-footer-garanty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img-foot {
  height: 54%;
}
.div-icon-social {
  display: flex;
  justify-content: flex-start;
  margin-top: 29px;
}
.icon-social {
  padding: 1px 15px 15px 1px;
  font-size: xx-large;
  color: #b5b0bd;
}
@media (min-width: 1500px) {
  .title-footer {
    font-size: 30px;
  }
  .title-footer1 {
    font-size: 35px;
  }
  .img-slide {
    width: 400px;
  }
}
@media (max-width: 1499px) and (min-width: 1100px) {
  .title-footer1 {
    font-size: 25px;
  }
  .img-slide {
    width: 300px;
  }
  .title-footer {
    font-size: 22px;
  }
}
@media (max-width: 1100px) {
  .form3 {
    flex: 0 0 auto;
    width: 50%;
  }
  .form4 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 500px) {
  .max-widhth99 {
    margin-top: 50px;
    max-width: 280px;
  }
  .text-1 {
    font-size: 23px;
  }
  .div-proteger {
    padding-left: 45px;
    padding-right: 45px;
  }
  .img-arrow {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .div-arrow {
    padding-left: 0px;
    padding-top: 15px;
  }
  .form3 {
    flex: 0 0 auto;
    width: 100%;
  }
  .form4 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1300px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media (max-width: 1300px) {
  .color-font-title {
    font-family: "Century Gothic";
    color: #000;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 26.4px;
    font-size: 22px;
  }
  .color-font-HomePageFooter {
    font-size: 15px;
    font-family: "Century Gothic";
    line-height: 1.7;
    white-space: nowrap;
    margin-top: 9px;
  }
}
@media (max-width: 830px) {
  .container-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .img-foot {
    height: 120px;
  }
  .carro-div {
    flex: 0 0 auto;
    width: 83.33333333%;
    padding-left: 20px;
  }
  .spec1 {
    display: none;
  }
}

.d-flex-a-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-j-c {
  display: flex;
  justify-content: center;
}

.d-flex-d-c-w {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.question-title {
  width: auto;
  width: initial;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #0c015f;
}
.question-sous-title {
  width: auto;
  width: initial;
  font-size: 20px;
  margin-top: 14px;
  font-family: -webkit-pictograph;
}
.form-souscription {
  margin: 0;
}
.margin0 {
  margin: 0;
}

.checkbox-divv {
  height: 160px;
  display: flex;
  align-items: center;
}
.label-checkboxx {
  font-size: 14px;
}
.checkboxx {
  margin-right: 10px;
}
.title-form-check {
  font-size: 14px;
  font-weight: 400;
}
.div-form-oui-non {
  border-bottom: #c7c7c7 solid 1px;
  padding: 12px 26px;
  display: flex;
  justify-content: space-between;
}
.form-check {
  padding-left: 30px;
}
.form-check-label {
  font-size: 12px;
  font-weight: bold;
}
.div-form-last {
  padding: 12px 26px;
  display: flex;
  justify-content: space-between;
}
.btn-continuer {
  float: right;
  margin-top: 50px;
  background-color: #c727cc;
  border-color: #c727cc;
  padding: 8px 50px;
  margin-left: 20px;
  margin-bottom: 50px;
}
.mon-smartphone {
  color: #0c015f;
  font-weight: bold;
  font-size: 14px;
  margin-top: 70px;
  margin-bottom: 30px;
}
.label-form {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}
.input-form {
  height: 50px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #b8b8b8;
  font-size: large;
  position: relative;
  width: 100%;
  padding-left: 10px;
}
select {
  color: #b8b8b8;
  padding-left: 20px;
}
option {
  color: black;
}
.marginTop30 {
  margin-top: 14px !important;
}
input#file-upload-button {
  display: none !important;
}
.convention-formule {
  display: flex;
  padding-top: 8px;
  justify-content: center;
  padding-left: 16px;
}

.fa-check.sous {
  background-color: #d9f9f6;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-annuler {
  float: right;
  margin-top: 50px;
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  padding: 8px 50px;
}

.div-steps-souscrption {
  width: 50px;
  height: 50px;
  background-color: #d4bff4;
  border-radius: 100px;
}

.div-steps-souscrption1 {
  width: 38px;
  height: 38px;
  background-color: #f3ebff;
  border-radius: 100px;
}

.header-souscription {
  background-color: #0c015f;
  height: 80px;
  margin: 0;
  display: flex;
}

.label-checkbox {
  font-size: 14px;
}
.checkbox {
  margin-right: 10px;
}

.card-box {
  box-shadow: 10pt 10pt 6pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
}

.div-steps-wait {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f3ebff;
  border-color: #d4bff4;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-wait-con {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}

.div-steps-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f3ebff;
  border-color: #aa74e8;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-active-con {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ea097d;
  border-color: #ea097d;
  border-width: 5px;
  border-style: solid;
  z-index: 2;
}
.div-steps-done {
  width: 50px;
  height: 50px;
  background-color: #aa74e8;
  border-radius: 50%;
  z-index: 2;
}
.div-steps-done-con {
  width: 35px;
  height: 35px;
  background-color: #ea097d;
  border-radius: 50%;
  z-index: 2;
}
/***************************** confirmation *************************/
.dot {
  background-color: #d9f9f6;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 10px;
  margin-top: 7px;
}
.dot-label {
  font: normal normal normal 10pt/20pt Century Gothic;
  margin-bottom: 0;
}
.dot-value {
  font: normal normal bold 14pt/20pt Century Gothic;
}
.value-text {
  font: normal normal bold 12pt/24pt Century Gothic;
}
._divider {
  width: 100%;
  height: 2px;
  background-color: #0000000d;
  margin-top: 28px;
  margin-bottom: 28px;
}
.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/************************** payment **********************/
.radio-center {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.text-font5 {
  font: normal normal normal 14pt/26pt Century Gothic;
  color: black;
  letter-spacing: 0px;
}
.text-font-bold {
  font: normal normal bold 14pt/26pt Century Gothic;
  letter-spacing: 0px;
}
.hr {
  width: 100% !important;
  height: 5px;

  margin-top: 25px;
  margin-left: -3rem;
  margin-right: -2rem;
  background-color: #d4bff4;
  z-index: 0;
}
.hr-con {
  width: 100% !important;
  height: 3px;

  margin-top: -30px;
  margin-left: -2rem;
  margin-right: -1rem;
  background-color: #9e9e9e;
  z-index: 0;
}
::-webkit-input-placeholder {
  text-align: center; 
}
:-ms-input-placeholder {
  text-align: center; 
}
::placeholder {
  text-align: center; 
}
.hr-active {
  width: 100% !important;
  height: 5px;
  margin-top: 25px;
  margin-left: -3rem;
  margin-right: -2rem;
  background-color: #aa74e8;
  z-index: 0;
}
.hr-active-con {
  width: 100% !important;
  height: 3px;
  margin-top: -30px;
  margin-left: -2rem;
  margin-right: -1rem;
  background-color: #ea097d;
  z-index: 0;
}

.text-step {
  text-align: center;
  font: normal normal normal 14px/14px Century Gothic;
  color: #000000;
  margin-top: 20px;
  margin-left: "-12px";
  white-space: "pre-wrap";
  margin-left: -1.2rem;
}
.text-step-active {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #000000;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.text-step-con {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #ea097d;
  margin-top: 20px;
  margin-left: "-12px";
  white-space: "pre-wrap";
  margin-left: -1.2rem;
}
.text-step-active-con {
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #ea097d;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.text-step-wait-con{
  text-align: center;
  font: normal normal bold 14px/14px Century Gothic;
  color: #9e9e9e;
  margin-top: 20px;
  margin-left: -13px;
  white-space: "pre-wrap";
}
.btn-contratct-div{
  display: flex;
  justify-content: center;
}

.oui-non-btn{
  height: 45px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*//////////////////////////////////////// */
.paragraphe-notice {
  text-align: justify;
  margin-top: 70px;
  font-size: 14px;
}
.btn-telecharger {
  margin-top: 50px;
  background-color: #c727cc;
  border-color: #c727cc;
  width: 100%;
  height: 50px;
}

/* membership */
.title-member {
  font: normal normal bold 30px/24px Century Gothic;
}
.label-member {
  font: normal normal normal 14px/18px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 2px;
}

.value-member {
  font: normal normal bold 14px/18px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 2px;
}
.row-member {
  margin-left: 15px;
}

.btn-wrapper {
  margin-bottom: 20px;
}
.btn-valid-w {
  background-color: #c727cc;
  color: white;
  width: 9rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  margin-bottom: 5px;
}
.btn-valid {
  background-color: #c727cc;
  color: white;
  width: 11rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  
}
.btn-back-w {
  background-color: #d4d4d4;
  color: white;
  margin-right: 10px;
  width: 9rem;
  font: normal normal normal 14px/26px Century Gothic;
  height: 50px;
  margin-bottom: 5px;
}

.btn-valid-w:hover{
  background-color: white;
  color: #C727CC;
  border-color: #C727CC;
}
.btn-valid:hover{
  background-color: white;
  color: #C727CC;
  border-color: #C727CC;
}


.nomenclature {
  width: auto;
  font-size: 12px;
  margin-left: 5px;
}
.nomenclature-container {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.boule-couleur {
  width: 12px;
  height: 12px;
  border-radius: 22px;
  margin-top: 6px;
}
.tab-container {
  background-color: #f4f2ff;
  margin-top: 50px;
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
  border-radius: 8px;
}
.title-tab {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.value-tab {
  font-size: 16px;
  text-align: center;
}
.tab-sinistres {
  min-width: 820px;
}
.doc-download {
  cursor: pointer;
}
.mp-3 {
  margin-bottom: 30px !important;
}
.row-tab {
  border-radius: 8px;
  border: #ebebeb 1px solid;
  padding: 16px 0;
  margin-top: 16px;
  margin-left: 0;
  margin-right: 0;
}
.tab-scroll {
  overflow-x: auto;
}
.btn-continuer:hover {
  background-color: white;
  color: #c727cc;
  border-color: #c727cc;
}
.succ {
  margin-top: 20%;
  text-align: center;
  font-size: larger;
  font-family: "FontAwesome";
  color: green;
}
.buttonEdit{
  background-color: #f958ff;

}
.body-sinistre {
  width: 100%;
  /* padding-left: 100px; */
  padding: 50px;
}
.rep-Ques {
  margin-top: 15px;
  font-size: 14px;
  font-family: "Century Gothic";
  font-weight: normal;
}

.space {
  margin-top: 16px;
}

.card-panne {
  width: 270px;
  height: 100px;
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
  margin-bottom: 22px;
}

.increase {
  width:25px;
  height:25px;
  cursor: pointer;
}
.check-panne {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
}

.label-panne {
  font: normal normal normal 18px/24px Century Gothic;
}
.card-container {
  width: 280px;
  height: 107px;
  background-color: color;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.card-container:active {
  -webkit-filter: grayscale(60%);
          filter: grayscale(60%);
}

.card-box-dash {
  box-shadow: 0pt 8pt 20pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
  width: 280px;
  height: 180px;
  background-color: #f9f4e1;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  align-items: center;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
}

#btn-add {
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: #c727cc;
  width: 280px;
  height: 60px;
  border-bottom-left-radius: 3pt;
  border-bottom-right-radius: 3pt;
  font: normal normal normal 16px/26px Century G;
  cursor: pointer;
}

/* #btn-add:active {
  background-color: #d57dd8;
} */

#btn-add:hover {
  background-color: #ffffff;
  color: #c727cc;
  border-style: solid;
  border-width: 1px;
}

.home {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
}

.nav-menu-bar {
  background-color: white;
  width: 0px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  left: -100%;
  transition: 850ms;
  z-index: 1000;
}

.nav-menu-bar.active {
  left: 0px;
  transition: 350ms;
  margin-top: 0px;
  width: 328px;
}
.nav-menu-s {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  position: absolute;
  top: -150%;
  /* left: -100%; */
  transition: 550ms;
  z-index: 1000;
}

.nav-menu-s.active {
  top: 80px;
  transition: 350ms;
  margin-top: 0px;
  height: auto;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  /* min-height: 72px; */
}

.nav-text1 {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 72px;
  margin-bottom: 95px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}
.nav-text div {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}

.nav-text1 a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font: normal normal normal 18px/25px Century Gothic;
  letter-spacing: 0px;
}


.nav-text a:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.nav-text div:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}

.nav-text1 a:hover {
  /* background-color: #1a83ff; */
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.selected a {
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}
.selected .div {
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 6px;
  border: 1pt solid #f8f8f8;

  border-right-style: solid;
  border-right-color: #c727cc;
  border-right-width: 5px;
}

.nav-menu-items {
  width: 100%;
  padding-left: 5px;
}

.navbar-toggle {
  background-color: rgb(150, 13, 13);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 6px;
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.main {
  margin-left: 328px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  /* padding: 0px 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enfin-garantie {
  font-size: 30px;
  font-weight: bold;
  color: white;
  font-family: "Century Gothic";
  text-align: center;
}
.sumilateur{
  margin-top: 10%;
  padding-right: 0px;
  margin-right: 0px;
}
.simulez-prix1 {
  font-family: "Century Gothic";
  font-size: 18px;
  color: white;
  text-align: center;
  margin-top: 20px;
}
.bloc-raison {
  background-color: #0c015f;
  margin: 0;
  display: flex;
  justify-content: center;
}
.title-bloc-raison {
  font-family: "Century Gothic";
  color: white;
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.paragraphe-bloc-raison {
  color: white;
  font-size: 16px;
}
.title-paragraphe-bloc-raison {
  color: white;
  font-size: 18px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
}
.bloc-paragraphe-bloc-raison {
  margin: 40px;
  border-bottom: solid 0.5px white;
}
.title-bloc-couvert {
  font-family: "Century Gothic";
  color: #000000;
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.title-bloc-couvert1 {
  font-family: "Century Gothic";
  color: #000000;
  margin-top: 50px;
  font-size: 27px;
  font-weight: bold;
  text-align: center;
}
.title-parahraphe-couvert {
  color: #000000;
  font-size: 22px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
}
.parahraphe-couvert {
  font-size: 15px;
  margin-bottom: 30px;
}
.parahraphe-couvert1 {
  font-size: 19px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Century Gothic";
}
.image-parahraphe-couvert {
  max-width: 60px;
  max-height: 60px;
  /* margin-bottom: 20px; */
}
.image-parahraphe-couvert1 {
  max-width: 70px;
  max-height: 63px;
  margin-bottom: 20px;
}
.bloc-paragraphe-couvert {
  padding: 0 25px;
  display: flex;
  margin-bottom: 2%;
}
.lasttitle100 {
  font-size: 22px;
  font-weight: bold;
  margin-top: -10px;
  font-family: "Century Gothic";
  color: #ffffff;
}
.firsttitle100 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Lato";
  font-family: "Century Gothic";
  color: #ffffff;
}
.paragraphe-avantage {
  font-size: 17px;
  margin-top: 20px;
  font-family: "Century Gothic";
  color: #ffffff;
}
.title-parahraphe-stepper {
  color: #e4197d;
  font-size: 18px;
  font-family: "Century Gothic";
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.bloc-sinistre {
  border-radius: 8px;
  padding: 40px 15px 15px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  box-shadow: #f0f0f0 2pt 2pt 1pt 0px;
  width: 265px;
}
.num-stepperr {
  position: absolute;
  height: 48px;
  width: 48px;
  background-color: #9285d7;
  border-radius: 60px;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  right: 40%;
  top: -20px;
}
.bloc-sinistre-boostrap {
  padding-left: 17px;
  padding-right: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 500px) {
  .bloc-sinistre-boostrap {
    padding-right: 17px;
    align-items: center;
    flex-direction: column;
    padding: 17px 17px 17px 17px;
  }
  .sumilateur{
    margin-top: 5%;
  }
}

.card-box-4 {
  box-shadow: 0pt 0pt 10pt #0000000d;
  border: 1pt solid #f8f8f8;
  border-radius: 10pt;
  margin: auto;
  width: 80%;
  height: auto;
  padding: 50px;
  margin-top: 1.8rem;
}
.faq-title {
  font: normal normal bold 32px/40px Century Gothic;
  letter-spacing: 0px;
  margin-bottom: 35px;
  margin-top: 40px;
  color: #ad81ea;
}
.faq-sub-title {
  font: normal normal normal 22px/34px Century Gothic;
  letter-spacing: 0px;
  margin-block: 10px;
  /* color: #FFFFFF; */
}
.faq-text {
  font: normal normal normal 16px/24px Century Gothic;
  letter-spacing: 0px;
  color: #3b3b3b;
  white-space: pre-line;
}
.faq-drop-item {
  border-inline: none;
  border-top: none;
}

.textCenter {
  text-align: center;
}

.Right {
  display: flex;
  justify-content: right;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Center {
  justify-content: center;
  display: flex;
}

.margin {
  margin: 10px;
}

.row {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.absoluteCenter {
  top:10px;
  right: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
}
.bigContainer {
  width: 1000px;
}
.smallContainer{
  width: 400px;
  height: 230px;
  border-radius: 10px ;
  box-shadow: 0px 0px 15px rgba(163, 163, 163, 0.589);
}
.logoIcon{
  width: 150px;
}
.century {
  font-family:"Century Gothic" !important ;
}
.blod {
  font-weight: bold;
}
.larger {
  font-size: 28px;
}
.greenCircle {
  background-color: green;
  border-radius: 50px;
  width: 50px;
  height: 50px;
}
.qrCode {
  height: 100vh;
}
.Margin {
  margin-top: 70px;
}


