.card-container {
  width: 280px;
  height: 107px;
  background-color: color;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-inline-end: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.card-container:active {
  filter: grayscale(60%);
}
